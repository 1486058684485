import { NgIf, NgClass, NgFor, AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { IconsModule, TooltipModule, AutoCompleterModule, InputsModule } from 'ng-uikit-pro-standard';
import { BaseAutocompleteDadataClass } from 'src/app/components/autocomplete-dadata/modules/base-autocomplete-dadata.class';
import { FormFieldValidationComponent } from 'src/app/components/form-field-validation/form-field-validation.component';
import { TemplateButtonComponent } from 'src/app/components/template-button/template-button.component';
import { AppIconComponent } from 'src/app/modules/app-icon/app-icon.component';
import { IconsSet } from 'src/app/modules/app-icon/icons';
import { AppFormFieldClass } from 'src/app/providers/_classes/app.form.field.class';
import { ClickOutsideDirective } from 'src/app/providers/_directives/click-outside/click-outside.directive';
import { AuthenticationService } from 'src/app/providers/_services/authentication.service';
import { DictionaryService } from 'src/app/providers/_services/dictionary.service';


export interface IFiasInfo {
  field_name: string; // "address"
  id?: number; // 4
  info: {
    fias_id: string; // "26a25efa-4f63-4d4b-8db6-dfc29cdaab24"
  };
  service_request_id?: number;
}

@Component({
  selector: 'app-autocomplete-dadata',
  templateUrl: './autocomplete-dadata.component.html',
  styleUrls: ['./autocomplete-dadata.component.scss'],
  providers: AppFormFieldClass.providers(AutocompleteDadataComponent),
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    NgClass,
    IconsModule,
    TooltipModule,
    ClickOutsideDirective,
    AutoCompleterModule,
    InputsModule,
    ReactiveFormsModule,
    TemplateButtonComponent,
    NgFor,
    FormFieldValidationComponent,
    AppIconComponent,
    AsyncPipe,
  ],
})
export class AutocompleteDadataComponent extends BaseAutocompleteDadataClass implements OnInit, OnChanges, OnDestroy {
  IconsSet = IconsSet;

  constructor(
    public dictionaryService: DictionaryService,
    public cdr: ChangeDetectorRef,
    public fcd: FormGroupDirective,
    public sanitizer: DomSanitizer,
    public auth: AuthenticationService,
  ) {
    super(dictionaryService, cdr, fcd, sanitizer);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  public selectItemValue(item: { text: any }, dict: any[]): void {
    super.selectItemValue(item, dict);

    if (this.hasFiasInfo) {
      const selectedObject = dict.find((element) => item.text === element);
      if (selectedObject === undefined || selectedObject === null) return;

      this.fiasSelected = false;
      const {
        area_fias_id, city_district_fias_id, city_fias_id, flat_fias_id, house_fias_id, region_fias_id, room_fias_id,
        settlement_fias_id, stead_fias_id, street_fias_id, fias_actuality_state, fias_code, fias_id, fias_level,
        geo_lat, geo_lon
      } = selectedObject.data;
      const fias_info = {
        area_fias_id, city_district_fias_id, city_fias_id, flat_fias_id, house_fias_id, region_fias_id, room_fias_id,
        settlement_fias_id, stead_fias_id, street_fias_id, fias_actuality_state, fias_code, fias_id, fias_level,
        geo_lat, geo_lon
      };

      this.fias_control.setValue(fias_info);
      this.fias_control.markAsDirty();
      this.fias_control_init.setValue(fias_id ? [{ field_name: this.formControlName, info: fias_info }] : null);
      this.fias_control_init.markAsDirty();
      setTimeout(() => {
        this.fiasSelected = true;
        this.fcd.form.updateValueAndValidity();
      }, 500);
    }
  }
}
