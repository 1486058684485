import { Directive, OnInit, OnChanges, OnDestroy, Input, ViewChild, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { isNaN, isString } from 'lodash';
import { MdbAutoCompleterComponent } from 'ng-uikit-pro-standard';
import { AppFormFieldClass } from 'src/app/providers/_classes/app.form.field.class';
import { EInputType } from 'src/app/providers/_const/input.type.const';
import { InputCategory, InputAppend } from 'src/app/providers/_enum';
import { REGEXP } from 'src/app/providers/_regexp';
import { DictionaryService } from 'src/app/providers/_services/dictionary.service';
import { FormSuggestionItem, FormSuggestionsService } from 'src/app/providers/_services/form-suggestions.service';
import { undefinedOrNullOrEmptyString, decimalTrunc, getValidCoord, strNumFormat } from 'src/app/providers/_utils/utils';

@Directive()
export class BaseInputClass extends AppFormFieldClass<string | number> implements OnInit, OnChanges, OnDestroy {
  @Input() public category: InputCategory = InputCategory.input;
  @Input() public append: InputAppend;
  @Input() public isLoaded = true;
  @Input() rowsCount = 2;
  @Input() tooltip: string;
  @Input() maxMinTimeout = 0;
  @Input() minValue = 0;
  @Input() maxValue = 1e15;
  @Input() filled = false;
  @Input() decimalPlaces: number;
  @Input() decimalNum: number;
  @Input() validCoord: number;
  @Input() autocompleteFormId: string;
  @Input() autocompleteFormIdentity: string;
  @Input() autocompleteStaticValues: (string | number)[];
  @Input() isConvertToCurrency = false;
  @Input() isConvertToCurrencyAsString = false;
  @Input() disabledAsUrl = false;
  @Input() valueMod: (value: any) => any;

  public InputCategory = InputCategory;
  public InputAppend = InputAppend;
  public EInputType = EInputType;

  public maxMinApplyTimeout: number;
  public suggestions: FormSuggestionItem[];

  private masks = {
    phone: false,
  };

  @ViewChild('auto', { static: false }) auto: MdbAutoCompleterComponent;

  get hasAutocompleteForm(): boolean {
    return !!this.autocompleteFormIdentity && !!this.autocompleteFormId;
  }

  get hasAutocomplete() {
    return this.hasAutocompleteForm || (this.autocompleteStaticValues && this.autocompleteStaticValues.length);
  }

  constructor(
    public cdr: ChangeDetectorRef,
    public fcd: FormGroupDirective,
    public dictionaryService: DictionaryService,
    public sanitizer: DomSanitizer,
    public suggestionsService: FormSuggestionsService,
  ) {
    super(fcd, cdr, dictionaryService);
  }
  private updateControl(viewValue: string | null, sendValue: string | null, emitEvent: boolean = false): void {
    this.control.setValue(viewValue, { emitEvent });
    this.updateFormValue(sendValue, { emitEvent });
  }

  writeValue(value: string): void {
    if (typeof this.valueMod === 'function') {
      this.updateControl(this.valueMod(value), value, false);
      return;
    }

    if (!undefinedOrNullOrEmptyString(value)) {
      if (this.type === EInputType.number || this.type === EInputType.number_with_null) {
        this.updateControl(strNumFormat(value), strNumFormat(value), false);
      } else if (this.type === EInputType.phone_strict) {
        this.masks.phone = this.testPhoneByMask(value);
        this.updateControl(value, value, false);
      } else if (this.type === EInputType.mineral_license || this.type === EInputType.rafp_number) {
        this.updateControl(value, value, false);
      } else {
        let viewValue = value;
        if (typeof this.decimalNum === 'number') {
          const nativeElement = this.getNativeElement();
          const isFocused = nativeElement && document.activeElement === nativeElement;
          if (!isFocused) viewValue = Number(value).toFixed(this.decimalNum);
        }
        this.updateControl(viewValue, value, false);
      }
    } else if (this.formValue !== null) {
      this.updateControl(value, value, false);
    }

    if (!value && this.type === EInputType.phone_strict) {
      this.masks.phone = true;
    }

    if (!this.destroyed) {
      this.cdr.markForCheck();
    }
  }

  ngOnInit() {
    super.ngOnInit();

    this.subscriptions.push(
      this.control.valueChanges.subscribe((v) => {
        if (this.formValue === v) return;

        if (this.isConvertToCurrency) {
          v = this.convertToCurrency(v);
          this.updateFormValue(undefinedOrNullOrEmptyString(v) ? null : +v);
        } else if (this.isConvertToCurrencyAsString) {
          v = this.convertToCurrency(v);
          this.updateFormValue(undefinedOrNullOrEmptyString(v) ? null : v);
        } else if (
          this.type === EInputType.number ||
          this.type === EInputType.number_with_null ||
          this.type === EInputType.percentage ||
          this.type === EInputType.currency
        ) {
          this.updateFormValue(undefinedOrNullOrEmptyString(v) ? null : +v);
        } else {
          this.updateFormValue(v);
        }
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.autocompleteFormId || changes.autocompleteFormIdentity || changes.formControlName) {
      const newIdentity = changes.autocompleteFormIdentity ? changes.autocompleteFormIdentity.currentValue : this.autocompleteFormIdentity;
      const newId = changes.autocompleteFormId ? changes.autocompleteFormId.currentValue : this.autocompleteFormId;
      const newControlName = changes.formControlName ? changes.formControlName.currentValue : this.formControlName;

      if (newIdentity && newId && newControlName) {
        this.suggestions =
          newId === 'new'
            ? this.suggestionsService.get(newIdentity, newControlName)
            : this.suggestionsService.get(newIdentity, newControlName).filter((item) => item.formKey !== newId);
      } else {
        this.suggestions = [];
      }
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.maxMinApplyTimeout) {
      window.clearTimeout(this.maxMinApplyTimeout);
    }
  }

  public convertToCurrency(value) {
    return value.replace(/\s/g, '').replace(/,/g, '.');
  }

  public onClearButtonClick() {
    this.control.setValue('', { emitEvent: false });
    this.propagateTouched(null);
    this.control.markAsDirty();
    this.updateFormValue('');
  }

  getPos(element: HTMLInputElement) {
    return element.selectionStart;
  }

  getNewPos(element: HTMLInputElement) {
    const pos = element.selectionStart;

    if (pos + 1 < element.value.length) {
      return pos;
    }

    return null;
  }

  setPos(element: HTMLInputElement, pos: number) {
    element.selectionStart = pos;
    element.selectionEnd = pos;
  }

  public onKeydown(event: KeyboardEvent) {
    switch (event.key) {
      case 'Meta':
      case 'Backspace':
      case 'ArrowLeft':
      case 'ArrowRight':
        return;
    }

    if (this.type === EInputType.mineral_license) {
      if (!event.metaKey && event.key.toUpperCase().match(/[A-Z]/)) {
        event.preventDefault();
        return;
      }

      const isValid = REGEXP.mineralLicense.test(this.control.value);

      if (isValid) {
        const pos = this.getPos(event.target as HTMLInputElement);
        let val = this.control.value[pos];
        let strictEqual = false;

        if (event.key === ' ') {
          strictEqual = true;
        } else if (val === ' ') {
          val = this.control.value[pos - 1];
        }

        const equal = strictEqual ? event.key === val : isNaN(+event.key) === isNaN(+val);

        if (!equal) {
          event.preventDefault();
        }
      }
    }
  }

  public onInput(event: any): void {
    this.propagateTouched(null);

    if ([EInputType.number, EInputType.number_with_null, EInputType.percentage, EInputType.currency].indexOf(this.type) >= 0) {
      const strValue = event.target.value;
      const value = this.type === EInputType.number_with_null && undefinedOrNullOrEmptyString(strValue) ? null : +strValue;

      if (this.minValue !== undefined && (isNaN(value) || value < this.minValue)) {
        this.control.setValue(this.minValue);
      } else if (this.maxValue !== undefined && (isNaN(value) || value > this.maxValue)) {
        this.control.setValue(this.maxValue);
      } else if (this.decimalPlaces !== undefined) {
        const truncStrValue = decimalTrunc(strValue, this.decimalPlaces);
        if (truncStrValue !== strValue) {
          this.control.setValue(truncStrValue);
        }
      }
    } else if (this.type === EInputType.phone_strict) {
      const value = event.target.value;

      if (!this.masks.phone) {
        this.masks.phone = this.testPhoneByMask(value);
      }

      if (this.masks.phone && !this.testPhoneByMask(value)) {
        const maskValue = this.inputWithMask(value);

        if (maskValue !== value) {
          this.control.setValue(maskValue);
        }
      }
    } else if (this.type === EInputType.mineral_license) {
      const value = event.target.value;
      const newPos = this.getNewPos(event.target);

      const maskValue = this.mineralLicenseWithMask(value);

      if (maskValue !== value) {
        this.control.setValue(maskValue);

        if (typeof newPos === 'number') {
          this.setPos(event.target, newPos);
        }
      }
    } else if (this.type === EInputType.rafp_number) {
      const value = event.target.value;
      const newPos = this.getNewPos(event.target);

      const maskValue = this.rafpNumberWithMask(value);

      if (maskValue !== value) {
        this.control.setValue(maskValue);

        if (typeof newPos === 'number') {
          this.setPos(event.target, newPos);
        }
      }
    }
    this.onChange.emit();
  }

  public onBlur(): void {
    if (typeof this.validCoord === 'number') {
      this.control.setValue(getValidCoord(this.control.value, this.validCoord));
    }

    if (typeof this.decimalNum === 'number') {
      this.control.setValue(Number(this.control.value).toFixed(this.decimalNum));
    }

    if (this.hasAutocompleteForm) {
      this.suggestionsService.put(this.autocompleteFormIdentity, this.autocompleteFormId, this.formControlName, this.control.value);
    }
  }

  public selectSuggestion(event: any) {
    this.control.setValue(event.text.value);

    // not delete, for rerender app-form-field-validation
    this.isLoaded = false;
    window.setTimeout(() => (this.isLoaded = true), 0);

    this.auto.hide();
  }

  public clickOutside() {
    if (this.auto) {
      this.auto.hide();
    }
  }

  private rafpNumberWithMask(value: string): string {
    if (!value || !isString(value)) {
      return value;
    }

    return value.slice(0, 11);
  }

  private mineralLicenseWithMask(value: string): string {
    if (!value || !isString(value)) {
      return value;
    }

    const matchGroups = [/^[А-Я]{1,3}/, /[0-9]{1,6}/, /[А-Я]+$/];

    value = value.toUpperCase();

    const match1 = value.match(matchGroups[0]);
    const match2 = value.match(matchGroups[1]);
    const match3 = value.match(matchGroups[2]);

    const newValue = [];

    if (match1) {
      newValue.push(match1[0].slice(0, 3));
    }

    if (match1 && match2) {
      newValue.push(match2[0].slice(0, 6));
    }

    if (match2 && match3) {
      newValue.push(match3[0].slice(0, 2));
    }

    return newValue.join(' ');
  }

  private testPhoneByMask(phone: string): boolean {
    if (!phone) {
      return true;
    }

    if (!isString(phone)) {
      return false;
    }

    return REGEXP.phoneStrict.test(phone);
  }

  private inputWithMask(phone: string): string {
    if (!phone || !isString(phone) || '+7 ('.indexOf(phone) === 0) {
      return phone;
    }

    if (phone.indexOf('+7') === 0) {
      phone = phone.substr(2);
    }

    const digits = phone.replace(/[^0-9]/gi, '');

    const dsub = (from: number, length?: number) => {
      return digits.substr(from, length);
    };

    let mask = '+7 (';

    if (digits.length >= 1) {
      mask = mask + `${dsub(0, 3)}`;
    }

    if (digits.length >= 4) {
      mask += `) ${dsub(3, 3)}`;
    }

    if (digits.length >= 7) {
      mask += `-${dsub(6, 2)}`;
    }

    if (digits.length >= 9) {
      mask += `-${dsub(8, 2)}`;
    }

    if (digits.length > 10) {
      mask = `${mask} доб. ${dsub(10, 5)}`;
    }

    return mask;
  }
}
