import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IEssence } from 'src/app/providers/_interfaces/essence.interface';
import { adapter, EssenceState } from 'src/app/store/essence/reducer';

export const featureName = 'essence';

const featureSelector = createFeatureSelector<EssenceState>(featureName);

const { selectEntities } = adapter.getSelectors();

export const selectEssenceEntities = createSelector(featureSelector, selectEntities);

export const selectCurrentId = createSelector(featureSelector, (state: EssenceState) => state?.currentEssenceId);

export const selectCurrentEssence = createSelector(
  selectEssenceEntities,
  selectCurrentId,
  (essences, currentEssenceId) => (essences && currentEssenceId && essences[currentEssenceId]) as IEssence,
);

export const selectNeedLoadRoot = createSelector(featureSelector, (state: EssenceState) => state?.needLoadRoot);

export const selectCurrentRouteParams = createSelector(featureSelector, (state: EssenceState) => ({
  id: state?.currentEssenceId,
  type: state?.currentEssenceType,
  route: state?.currentRoute,
  treePath: state?.currentTreePath,
  routeParams: state?.currentRouteParams,
  routeRelations: state?.currentRouteRelations,
  obj: state?.currentQueryObject,
  operation: state?.currentOperation,
  sectionName: state?.currentSectionName,
  urlForEssence: state?.urlForEssence,
}));

export const selectCurrentOperation = createSelector(featureSelector, (state: EssenceState) =>
  state?.currentOperation,
);


export const selectCurrentEssenceType = createSelector(featureSelector, (state: EssenceState) => state?.currentEssenceType);

export const selectCurrentRouteData = createSelector(
  featureSelector,
  selectCurrentEssence,
  (state: EssenceState, currentEssence: IEssence) => ({
    currentEssence,

    currentObject: state?.currentObject,
    currentForms: state?.currentForms,
    treePath: state?.currentTreePath,
    currentTree: state?.currentTree,
  }),
);
