<!-- HEADER -->
<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <app-icon [name]="IconsSet.closeModal" width="11px" height="11px"></app-icon>
  </button>
  <h4 class="modal-title">Ошибка</h4>
</div>
<!-- /HEADER -->

<!-- BODY -->
<div class="modal-body">
  <div class="modal-wrapper">
    <ng-container *ngIf="!isStandardDialog; else standardErrors">
      <div class="rpn-dl d-flex" *ngIf="errors?.length === 1; else errorsTemplate">
        <div class="rpn-dd" [innerHTML]="errors[0].message"></div>
      </div>

      <ng-template #errorsTemplate>
        <div class="rpn-dl d-flex" *ngFor="let error of errors; let index = index">
          <div class="rpn-dt">{{index + 1}}.&nbsp;</div>
          <div class="rpn-dd" [innerHTML]="error.message"></div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>
<!-- /BODY -->

<!-- FOOTER -->
<div class="modal-footer justify-content-center" *ngIf="isStandardDialog">
  <small class="pt-1">
    <a href="tel:84955653438">
      <app-icon #icon [name]="icon.IconsSet.handset" width="18px" height="18px"></app-icon>
      <span>8 (495) 565-34-38</span>
    </a>
  </small>
  <small class="pt-1">
    <a href="mailto: helpdesk@rpn.gov.ru">
      helpdesk@rpn.gov.ru
    </a>
  </small>
</div>
<div class="modal-footer justify-content-center" *ngIf="!isStandardDialog">
  <button class="rpn-button rpn-button_md rpn-button_bordered-dark" mdbBtn type="button" color="default" (click)="close()">
    Закрыть
  </button>
</div>
<!-- /FOOTER -->

<ng-template #standardErrors>
  <div>Ошибка, попробуйте снова или обратитесь в тех. поддержку</div>
  <div class="text-danger">{{content?.error?.error?.message}}</div>
</ng-template>
