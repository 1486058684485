import { Location, NgIf, NgClass, NgTemplateOutlet, NgStyle, NgFor, AsyncPipe } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, Output, ViewChild, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { ModalModule, CardsModule, IconsModule, TooltipModule } from 'ng-uikit-pro-standard';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { AppIconComponent } from 'src/app/modules/app-icon/app-icon.component';
import { IconsSet } from 'src/app/modules/app-icon/icons';
import { DetailsEditModeCardActionsComponent } from 'src/app/modules/details-edit-mode/details-edit-mode-card/details-edit-mode-card-actions/details-edit-mode-card-actions.component';
import { DetailsEditModeCardActions, DetailsToolbarActions } from 'src/app/modules/details-edit-mode/details-edit-mode-card/details-edit-mode-card.interface';
import { LabelRouteComponent } from 'src/app/modules/label-route/label-route.component';
import { EnvelopeSpinnerDirective } from 'src/app/providers/_directives/spinner/spinner.directive';
import { IReportTransition } from 'src/app/providers/_interfaces/report.interface';
import { AppBreakpointsService } from 'src/app/providers/_services/app.breakpoints.service';
import { EssenceSelectors } from 'src/app/store';


@Component({
  selector: 'app-details-edit-mode-card',
  templateUrl: './details-edit-mode-card.component.html',
  styleUrls: ['./details-edit-mode-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [Location],
  imports: [
    NgIf,
    ModalModule,
    NgClass,
    NgTemplateOutlet,
    CardsModule,
    NgStyle,
    LabelRouteComponent,
    IconsModule,
    TooltipModule,
    NgFor,
    AppIconComponent,
    EnvelopeSpinnerDirective,
    DetailsEditModeCardActionsComponent,
    AsyncPipe
  ],
})
export class DetailsEditModeCardComponent implements OnChanges {
  @Input() isLoaded: boolean;
  @Input() title: string;
  @Input() hasHiddedTitle: boolean;
  @Input() tooltip: string;
  @Input() formData: UntypedFormGroup;
  @Input() isDisabled: boolean;
  @Input() hasDelete: boolean;
  @Input() hasMarkForDelete: boolean;
  @Input() hasRestore: boolean;
  @Input() hasBack: boolean;
  @Input() customButtons: DetailsEditModeCardActions[];
  @Input() customAdditionalButtons: DetailsEditModeCardActions[];
  @Input() isModalView: boolean;
  @Input() showModalTransition: IReportTransition;
  @Input() customStyles: any = {};
  @Input() noOverflow = false;
  @Input() showActions = true;

  @Input() customBtnTitles: any[];
  public loading$ = new BehaviorSubject(false);

  @Output() action = new EventEmitter<DetailsEditModeCardActions>();
  @Output() modalClosed = new EventEmitter<void>();

  public isNewObject$: Observable<boolean> = this.store.pipe(
    select(EssenceSelectors.selectCurrentOperation),
    map((operation) => operation === 'new'),
  );

  private initTitleButton: DetailsToolbarActions[] = [
    {
      title: '',
      action: () => {
        this.loading$.next(true);
        this.deleteObject.emit(this.formData);
      },
      isDisabled: () => false,
      isShowed: () => this.hasDelete && !this.hasMarkForDelete && !this.isDisabled,
      icon: IconsSet.iconTrash,
      tooltip: 'Удалить',
      loading$: this.loading$,
    },
    {
      title: '',
      action: () => {
        this.loading$.next(true);
        this.markForDeleteRestoreObject.emit(this.formData);
      },
      isDisabled: () => false,
      isShowed: () => this.hasMarkForDelete && !this.isDisabled,
      icon: IconsSet.iconTrash,
      tooltip: 'Пометить на удаление',
      loading$: this.loading$,
    },
    {
      title: '',
      action: () => {
        this.loading$.next(true);
        this.restoreObject.emit(this.formData);
      },
      isDisabled: () => false,
      isShowed: () => this.hasRestore && !this.isDisabled,
      icon: IconsSet.iconUndo,
      tooltip: 'Восстановить',
      loading$: this.loading$,
    },
  ];
  private _titleButtons: DetailsToolbarActions[] = this.initTitleButton;
  @Input() set additionalTitleButtons(value: DetailsToolbarActions[]) {
    this._titleButtons = [...this.initTitleButton, ...value];
  }
  get titleButtons(): DetailsToolbarActions[] {
    return this._titleButtons;
  }

  @Output() deleteObject: EventEmitter<any> = new EventEmitter();
  @Output() markForDeleteRestoreObject: EventEmitter<any> = new EventEmitter();
  @Output() restoreObject: EventEmitter<any> = new EventEmitter();

  @ViewChild(DetailsEditModeCardActionsComponent) actions: DetailsEditModeCardActionsComponent;

  constructor(
    private location: Location,
    public br: AppBreakpointsService,
    private store: Store,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoaded) {
      console.log(this.isLoaded)
      this.loading$.next(!this.isLoaded);
    }
  }

  getCustomStyles() {
    return this.customStyles && this.customStyles['min-height'] !== undefined
      ? this.customStyles
      : { ...this.customStyles, 'min-height': 0 };
  }

  public refresh() {
    this.actions.refresh();
  }

  closeModal() {
    this.location.back();
    this.modalClosed.emit();
  }
}
