import { NgIf, NgClass, NgFor, NgSwitch, NgSwitchCase, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { Component, ChangeDetectionStrategy, OnInit, OnChanges, OnDestroy, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { InputsModule, TooltipModule, AutoCompleterModule, IconsModule } from 'ng-uikit-pro-standard';
import { NgxMaskDirective } from 'ngx-mask';
import { FormFieldValidationComponent } from 'src/app/components/form-field-validation/form-field-validation.component';
import { BaseInputClass } from 'src/app/components/input/base-input.class';
import { TemplateButtonComponent } from 'src/app/components/template-button/template-button.component';
import { AppFormFieldClass } from 'src/app/providers/_classes/app.form.field.class';
import { ClickOutsideDirective } from 'src/app/providers/_directives/click-outside/click-outside.directive';
import { NumberFormatPipe } from 'src/app/providers/_pipes/number-format.pipe';
import { AuthenticationService } from 'src/app/providers/_services/authentication.service';
import { DictionaryService } from 'src/app/providers/_services/dictionary.service';
import { FormSuggestionsService } from 'src/app/providers/_services/form-suggestions.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: AppFormFieldClass.providers(AppInputComponent),
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    InputsModule,
    TooltipModule,
    ReactiveFormsModule,
    NgClass,
    AutoCompleterModule,
    NgFor,
    IconsModule,
    NgxMaskDirective,
    ClickOutsideDirective,
    NgSwitch,
    NgSwitchCase,
    NgTemplateOutlet,
    TemplateButtonComponent,
    FormFieldValidationComponent,
    AsyncPipe,
    NumberFormatPipe,
  ],
})
export class AppInputComponent extends BaseInputClass implements OnInit, OnChanges, OnDestroy {
  constructor(
    public cdr: ChangeDetectorRef,
    public fcd: FormGroupDirective,
    public dictionaryService: DictionaryService,
    public sanitizer: DomSanitizer,
    public suggestionsService: FormSuggestionsService,
    public auth: AuthenticationService,
  ) {
    super(cdr, fcd, dictionaryService, sanitizer, suggestionsService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onMouseWheel() {
    return false;
  }
}
