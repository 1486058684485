import { NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { BadgeModule } from 'ng-uikit-pro-standard';
import { BehaviorSubject } from 'rxjs';
import { AppIconComponent } from 'src/app/modules/app-icon/app-icon.component';
import { fadeInAnimation } from 'src/app/providers/_animations/fade-in.animation';
import { SkeletonDirective } from 'src/app/providers/_directives/skeleton/skeleton.directive';
import { EnvelopeSpinnerDirective } from 'src/app/providers/_directives/spinner/spinner.directive';

export type PanelType = 'default' | 'small' | 'medium';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
  standalone: true,
  imports: [NgClass, SkeletonDirective, NgIf, BadgeModule, EnvelopeSpinnerDirective, AppIconComponent],
})
export class ExpansionPanelComponent implements OnChanges {
  @Input() title: string;
  @Input() additional: string;
  @Input() badge: string;
  @Input() rightInfo: string;
  @Input() isOpened = false;
  @Input() contentIsLoaded: any;
  @Input() disabled = false;
  @Input() showNextButton = false;
  @Input() headerClass = '';
  @Input() isOpened$: BehaviorSubject<boolean>;
  @Input() titleType: PanelType = 'default';

  @Output() isToggled: EventEmitter<boolean> = new EventEmitter();
  @Output() isNextToggled: EventEmitter<boolean> = new EventEmitter();

  constructor(private cdr: ChangeDetectorRef) {}

  togglePanel() {
    if (!this.disabled) {
      this.isOpened = !this.isOpened;
      this.isToggled.emit(this.isOpened);

      if (this.isOpened$) {
        this.isOpened$.next(this.isOpened);
      }
    }
  }

  toggleNextPanel() {
    if (!this.disabled) {
      this.isOpened = !this.isOpened;
      this.isToggled.emit(this.isOpened);
      this.isNextToggled.emit();

      if (this.isOpened$) {
        this.isOpened$.next(this.isOpened);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.contentIsLoaded) {
      this.cdr.detectChanges();
    }
  }
}
