import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import { Component, ChangeDetectionStrategy, OnInit, OnChanges, OnDestroy, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { IconsModule, TooltipModule, SelectModule } from 'ng-uikit-pro-standard';
import { FormFieldValidationComponent } from 'src/app/components/form-field-validation/form-field-validation.component';
import { BaseSelectClass } from 'src/app/components/select/base-select.class';
import { AppFormFieldClass } from 'src/app/providers/_classes/app.form.field.class';
import { ClickOutsideDirective } from 'src/app/providers/_directives/click-outside/click-outside.directive';
import { AuthenticationService } from 'src/app/providers/_services/authentication.service';
import { DictionaryService } from 'src/app/providers/_services/dictionary.service';
import { SvcRestService } from 'src/app/providers/_services/svc.rest.service';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: AppFormFieldClass.providers(AppSelectComponent),
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    IconsModule,
    TooltipModule,
    FormsModule,
    ClickOutsideDirective,
    SelectModule,
    ReactiveFormsModule,
    FormFieldValidationComponent,
    AsyncPipe,
  ],
})
export class AppSelectComponent extends BaseSelectClass implements OnInit, OnChanges, OnDestroy {
  constructor(
    public dictionaryService: DictionaryService,
    public svcRestService: SvcRestService,
    public store: Store,
    public cdr: ChangeDetectorRef,
    public fcd: FormGroupDirective,
    public sanitizer: DomSanitizer,
    public auth: AuthenticationService,
  ) {
    super(dictionaryService, svcRestService, store, cdr, fcd, sanitizer);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
