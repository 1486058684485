import { Injectable, TemplateRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { IconsSet } from 'src/app/modules/app-icon/icons';
import { INodeTree } from 'src/app/providers/_interfaces/common';
import { IOnvos } from 'src/app/providers/_interfaces/onvos/onvos.interface';
import { ERequestExtended } from 'src/app/providers/_interfaces/request.interface';
import { AuthenticationService } from 'src/app/providers/_services/authentication.service';
import { SvcRestService } from 'src/app/providers/_services/svc.rest.service';
import { TreeHelperService } from 'src/app/providers/_services/tree-helper.service';
import { getHash } from 'src/app/providers/_utils/utils';


export interface CopyBranchConfig {
  url?: string;
  async?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class OnvosRequestService {
  public onvosDataForRequest$ = new BehaviorSubject<IOnvos>(null);

  constructor(
    private svcRestService: SvcRestService,
    private authenticationService: AuthenticationService,
    private treeHelperService: TreeHelperService,
  ) {}

  getFromDeclareted(criteria_nvos_dict, supervision_criteria_dict, reportForm: UntypedFormGroup) {
    const criteria_nvos_dict_hash = getHash(criteria_nvos_dict, 'id');
    const supervision_criteria_dict_hash = getHash(supervision_criteria_dict, 'id');

    let criteria_nvos = reportForm.controls.criteria_nvos_pre.value || [];

    if (criteria_nvos.length > 0) {
      const oldCriteria = criteria_nvos.filter((x) => !criteria_nvos_dict_hash[x.id]);

      if (oldCriteria.length > 0) {
        reportForm.controls.registry_category.setValue('');
        reportForm.controls.registry_category.markAsDirty();
        reportForm.controls.registry_category_id.setValue(null);
        reportForm.controls.registry_category_id.markAsDirty();
        criteria_nvos = [];
        alert(
          'Критерии: ' +
            oldCriteria.map((x) => x.tag).join(', ') +
            ', указанные заявителем в разделе 2.1 заявки (заявления), являются устаревшими. Выберите новые критерии НВОС (пункт "Обоснование присвоения категории негативного воздействия объекта").',
        );
      }
    } else {
      alert(
        'Заявителем не указаны критерии в разделе 2.1. Выберите критерии НВОС (пункт "Обоснование присвоения категории негативного воздействия объекта").',
      );
    }

    reportForm.controls.criteria_nvos.setValue(criteria_nvos);
    reportForm.controls.criteria_nvos.markAsDirty();

    const supervision_criteria_pre_length = reportForm.controls.supervision_criteria_pre.value.length;

    if (supervision_criteria_pre_length && this.authenticationService.isROIV()) {
      alert(`Критерии присвоения федерального уровня государственного реестра не могут быть скопированы
      из раздела 2.1 "Категория объекта и уровень экологического надзора".
      В случае принадлежности объекта к федеральному государственному реестру, такая заявка
      (заявление) должна быть перенаправлена в территориальный орган Росприроднадзора`);
    }

    if (!this.authenticationService.isROIV()) {
      let supervision_criteria_pre = reportForm.controls.supervision_criteria_pre.value || [];
      supervision_criteria_pre = supervision_criteria_pre.filter((x) => {
        return supervision_criteria_dict_hash[x.id];
      });

      reportForm.controls.supervision_criteria.setValue(supervision_criteria_pre);
      reportForm.controls.supervision_criteria.markAsDirty();
    }
  }

  isOldRequest(done_at: string): boolean {
    const date = new Date('2022-09-01T00:00:00+0300');
    const done_at_date = new Date(done_at);

    if (done_at_date < date) {
      return true;
    }

    return false;
  }

  versionMap(mapData: { [key: string]: string }) {
    const keys = Object.keys(mapData);

    for (const key of keys) {
      if (this.version(key)) {
        return mapData[key];
      }
    }

    return null;
  }

  version = (ver: string) => {
    const requestTypeUrl = this.getRequestType();

    return this.versionForStr(ver, requestTypeUrl);
  };

  versionForStr(ver: string, str: string) {
    const v2Index = str.length - ver.length;

    if (v2Index < 0) {
      return false;
    }

    return str.indexOf(ver) === v2Index;
  }

  getUrlSegment(indexes: [number, string][]) {
    const segments = window.location.pathname.split('/');

    let result = '';
    let i = 0;

    while (indexes[i]) {
      result = result + (segments[indexes[i][0]] + indexes[i][1]);
      i++;
    }

    return result;
  }

  getRequestType = () => {
    return this.getUrlSegment([[5, '']]);
  };

  getRedirectUrl(state: RouterStateSnapshot, params: Partial<{ [key in ERequestExtended]: string }>): string {
    const segments = state.url.split('/');
    const requestTypeUrl = segments[5] || '';

    const lastSegment = params[requestTypeUrl];

    if (lastSegment) {
      segments.push(lastSegment);
    } else {
      throw new Error('Invalid route');
    }

    return segments.join('/');
  }

  fillOnvosDataForRequest = (id) => {
    if (this.onvosDataForRequest$.value) {
      return this.onvosDataForRequest$;
    }

    return this.svcRestService.fetchOnvItemById(id, { initParams: { relations: 'supervisory_territory_org_rel' } }).pipe(
      map((onvos) => {
        this.onvosDataForRequest$.next(onvos);

        return onvos;
      }),
    );
  };

  getSourceAdditionalTitleButtons(
    copyChemistryTemplate: TemplateRef<any>,
    reportForm: UntypedFormGroup,
    getCopyConfig: (copyValue) => CopyBranchConfig,
    subscriptions: Subscription[],
    copyBranchParentId: string,
  ) {
    const copyingSubstances$ = new BehaviorSubject(false);
    const copying$ = new BehaviorSubject(false);

    return [
      {
        title: '',
        action: () => this.copySource(0, copying$, reportForm, getCopyConfig, subscriptions, copyBranchParentId),
        isDisabled: () => false,
        isShowed: () => true,
        icon: IconsSet.buttonIconPlus,
        tooltip: 'Создать копию без ЗВ...',
        loading$: copying$,
      },
      {
        title: '',
        action: () => this.copySource(1, copyingSubstances$, reportForm, getCopyConfig, subscriptions, copyBranchParentId),
        isDisabled: () => false,
        isShowed: () => true,
        iconTemplate: copyChemistryTemplate,
        tooltip: 'Создать копию с ЗВ...',
        loading$: copyingSubstances$,
      },
    ];
  }

  copySource(
    isCopySubstances: 0 | 1,
    loading$: BehaviorSubject<boolean>,
    reportForm: UntypedFormGroup,
    getCopyConfig: (copyValue) => CopyBranchConfig,
    subscriptions: Subscription[],
    copyBranchParentId: string,
  ) {
    const copyValue = reportForm.value;
    let parent: INodeTree;

    this.treeHelperService.walkTree([this.treeHelperService.tree$.value], (branchTree, branch) => {
      if (branch.id === copyBranchParentId) {
        parent = branch;

        return { stopIterate: true };
      }

      return { stopIterate: false };
    });

    const config: CopyBranchConfig = getCopyConfig(copyValue);

    if (config.async) {
      loading$.next(true);

      const sub = this.svcRestService
        .postByUrl(config.url, { isCopySubstances })
        .pipe(
          tap((resp: { id: number | string }) => {
            this.treeHelperService.navigateAfterCopy(parent, resp.id);
          }),
          finalize(() => {
            loading$.next(false);
          }),
        )
        .subscribe();

      subscriptions.push(sub);
    } else {
      this.treeHelperService.addItem(parent, copyValue);
    }
  }
}
