import {
  OnvosInspectionsDataParams,
  OnvosMonitoringDataParams,
  OnvosProfileDataParams,
  SuperVisionLevelsEnum,
} from 'src/app/providers/_enum';
import { AmsState, ElectronicSealState } from 'src/app/providers/_interfaces/monitoring.interfaces';

export const UITextO = {
  messages: {
    incoming: 'Реестр входящих уведомлений',
    outgoing: 'Реестр исходящих уведомлений',
    archive: 'Уведомления в архиве',
    settings: 'Настройки подписок на уведомления',
  },
  sources: {
    discharge: 'Источник сбросов',
    stationary: 'Источник выбросов',
  },
  reports: {
    reports_constructor: 'Создание отчетов',
    history: 'История выгрузок',
    dashboards: 'Аналитические дашборды',
  },
  menu: {
    analytic_dashboards: 'Аналитические дашборды',
    onv_registry: 'Реестр ОНВОС',
    onv_registry_public: 'Государственный реестр объектов, оказывающих негативное воздействие на окружающую среду',
    onv_request: 'Заявки',
    onv_registry_lk: 'Мои объекты',
    map: 'Карта объектов',
    monitoring: 'Мониторинг выбросов',
    online_monitoring: 'Онлайн-мониторинг',
    monitoring_sensors: 'Датчики мониторинга выбросов/сбросов',
    monitoring_sensor: 'Датчик мониторинга выбросов/сбросов',
    source_add: 'Добавление источника для мониторинга выбросов/сбросов',
    pollutant_add: 'Добавление веществ для мониторинга выбросов/сбросов',
    analytic_report: 'Аналитическая отчётность',
    ksv: 'КСВ',
    stat: 'Статистика',
    permitting_document_add: 'Добавление разрешительного документа',
    permitting_document_reissue: 'Переоформление разрешительного документа',
    reports: 'Конструктор отчетов',
  },
  title: 'Государственный реестр объектов, оказывающих негативное воздействие на окружающую среду',
  table: {
    powerHoverTooltip: 'Выберите единицу измерения мощности',
    documentHoverTooltip: 'Выберите вид разрешительных документов',
    documentTypeHoverTooltip: 'Выберите наличие или отсутствие для объекта НВОС на отчетную дату разрешительных документов',
    emissionMassNameTooltip: 'Выберите показатель',
    pollutantNameTooltip: 'Выберите вещество',
  },
  monitoring: {
    actual_value: 'накопленный вред',
    annual_sum: 'норматив выброса',
    operating_mode: {
      test: 'Тестовая эксплуатация',
      production: 'Промышленная эксплуатация',
    }
  },
  monitoring_card: {
    menu: {
      review: 'Обзор',
      journal: 'Журнал передачи',
      monitoring_analytic: 'Аналитика',
      prediction: 'Прогнозирование НВОС',
    },
  },
  card: {
    registry_type: {
      [SuperVisionLevelsEnum.Regional]: 'Региональный',
      [SuperVisionLevelsEnum.Federal]: 'Федеральный',
      [SuperVisionLevelsEnum.Fcao]: 'Федеральный',
    },
    org_type: {
      to: 'Федеральный',
      roiv: 'Региональный',
    },
    org_type_icon: {
      to: 'Federal',
      roiv: 'Regional',
    },
    menu: {
      review: 'Обзор',
      permitting_documents: 'Разрешительные документы',
      requests: 'Информация об объекте',
      reports: 'Отчетность',
      profile: 'Профиль',
      risk_category: 'Категории риска',
      history: 'История изменений',
      notifications: 'Уведомления',
      monitoring: 'Мониторинг',
      checklists: 'Чеклисты и проверки',
      environmental_impact: 'Воздействие на ОС',
      knm: 'КНМ'
    },
    profile: {
      menu: {
        [OnvosProfileDataParams['control-category']]: 'Направления контроля',
        [OnvosProfileDataParams['calc-criteria']]: 'Критерии расчета категории риска',
        [OnvosProfileDataParams['extended-profile']]: 'Расширенный профиль',
      },
    },
    inspections: {
      menu: {
        [OnvosInspectionsDataParams['erp-inspections']]: 'Реестр плановых проверок',
        [OnvosInspectionsDataParams['knd-registry']]: 'Реестр проверок',
      },
    },
    monitoring: {
      objects: 'Объекты',
      sources: 'Источники',
      map: 'Карта',
      file_tooltip: `
      Требования для загрузки сертификатов: формат только .pem,
      алгоритм ECDSA (описанный в стандарте ANSI X9.62), длина применяемого ключа - от 192 до 256 бит`,
      menu: {
        [OnvosMonitoringDataParams.chart]: 'Мониторинг',
        [OnvosMonitoringDataParams.sensors]: 'Датчики',
        [OnvosMonitoringDataParams.notifications]: 'Уведомления',
      },
      ams_state_text: {
        [AmsState.OK]: 'АСИ работает исправно',
        [AmsState.ERROR]: 'В работе АСИ возникли ошибки',
        [AmsState.MAINTENANCE]: 'АСИ находится в процессе технического обслуживания',
        [AmsState.UNKNOWN]: 'Состояние АСИ неизвестно',
      },
      ams_state_colors: {
        [AmsState.OK]: '#2bca7d',
        [AmsState.ERROR]: '#dc3545',
        [AmsState.MAINTENANCE]: '#FF9C2B',
        [AmsState.UNKNOWN]: '#DDE1E6',
      },
      electronic_seal_state_text: {
        [ElectronicSealState.OK]: 'Целостность пломбы не нарушена',
        [ElectronicSealState.ERROR]: 'Пломба вскрыта',
        [ElectronicSealState.MAINTENANCE]: 'Пломба находится в процессе технического обслуживания',
        [ElectronicSealState.UNKNOWN]: 'Состояние пломбы неизвестно',
      },
      electronic_seal_state_colors: {
        [ElectronicSealState.OK]: '#d0fe77',
        [ElectronicSealState.ERROR]: '#ff2f2f',
        [ElectronicSealState.MAINTENANCE]: '#ffcc00',
        [ElectronicSealState.UNKNOWN]: 'Состояние пломбы неизвестно',
      },
    },
  },
  analytic_report: {
    menu: {
      history: 'Мои отчеты',
      new: 'Сформировать отчет',
    },
  },
  stat: {
    menu: {
      type: 'По типу и статусу отчёта',
      geo: 'По территории',
    },
  },
  waste_disposal_facility: {
    onvos_waste_storage_facts: {
      title: 'Количество отходов, находящихся на хранении',
      annual_value: 'Фактическая масса отходов, находящихся на хранении',
    },
    onvos_waste_facts: {
      title: 'Количество и состав отходов',
      annual_value: 'Фактическая масса размещенных отходов',
    },
    annual_value_unit_waste_activity_id: {
      1: ', т',
      2: ' (тонн за год, предшествующий году постановки на учет)',
    },
  },
  discharge_source: {
    def_name: 'Сбросы осуществляются в ЦСВ',
  },
};
