import { NgIf, NgFor } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { InputUtilitiesModule, TooltipModule } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-form-field-validation',
  templateUrl: './form-field-validation.component.html',
  styleUrls: ['./form-field-validation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, InputUtilitiesModule, TooltipModule, NgFor],
})
export class FormFieldValidationComponent {
  @Input() value: any;
  @Input() valid: boolean;
  @Input() errors: any;
  @Input() nonImportantErrors: string[] = [];
  @Input() dirty: boolean;
  @Input() notSavedDataMessage: boolean;
}
