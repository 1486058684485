export const UITextUserLKAE = {
  title: 'Личный кабинет аттестованного эксперта',
}
export const UITextUserLKOI = {
  title: 'Личный кабинет общественного инспектора',
}
export const UITextUser = {
  menu: {
    reports: 'Заявления ГЭЭ',
    requests: 'Заявления',
    other: 'Другое',
  },
  title: 'Личный кабинет природопользователя',
};

export const UITextStaff = {
  menu: {
    reports: 'Заявления ГЭЭ',
    requests: 'Заявления',
    other: 'Другое',
  },
  title: 'Кабинет сотрудника ведомства',
};

export const UITextCommon = {
  notFound: 'Данные отсутствуют',
  reportNotFound: 'Отчет отсутствует',
};

export const UITextLicense = {
  // eslint-disable-next-line max-len
  title: 'Реестр лицензий на деятельность по сбору, транспортированию, обработке, утилизации, обезвреживанию, размещению отходов I-IV классов опасности',
};

export const UIShortAdjustReasonStatus = {
  category: 'Изменение критериев НВОС',
  duplicate: 'Исключение дубликата объекта НВОС',
  disunion: 'Утрата единства назначения объектов НВОС',
  union: 'Объединение объектов НВОС',
  mistake: 'Исправление допущенных ошибок в сведениях об объекте НВОС',
};

export const UITextDocumentsRegistry = {
  title: 'Реестр документов',
};
