import { NgIf, AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, EMPTY, merge, Observable } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { AutocompleteComponent } from 'src/app/components/autocomplete/form/autocomplete.component';
import { AutocompleteDadataComponent } from 'src/app/components/autocomplete-dadata/form/autocomplete-dadata.component';
import { AppDatePickerComponent } from 'src/app/components/date-picker/form/date-picker.component';
import { AppInputComponent } from 'src/app/components/input/form/input.component';
import { AppSelectComponent } from 'src/app/components/select/form/select.component';
import { SwitchboxComponent } from 'src/app/components/switchbox/switchbox.component';
import { BaseSection } from 'src/app/modules/details-edit-mode/base-section/base-section.class';
import { BaseSectionFacade } from 'src/app/modules/details-edit-mode/base-section/base-section.facade';
import { DetailsEditModeCardComponent } from 'src/app/modules/details-edit-mode/details-edit-mode-card/details-edit-mode-card/details-edit-mode-card.component';
import { DetailsToolbarActions } from 'src/app/modules/details-edit-mode/details-edit-mode-card/details-edit-mode-card.interface';
import { fadeInAnimation } from 'src/app/providers/_animations/fade-in.animation';
import { AppValidatorDirective } from 'src/app/providers/_directives/validator/validator.directive';
import { TreeHelperService } from 'src/app/providers/_services/tree-helper.service';



export enum GECVAuthorType {
  legal = 'legal',
  individual = 'individual',
}

@Component({
  selector: 'app-gecv-author',
  templateUrl: './gecv-author.component.html',
  styleUrls: ['./gecv-author.component.scss'],
  animations: [fadeInAnimation],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    DetailsEditModeCardComponent,
    AppSelectComponent,
    AutocompleteDadataComponent,
    AppInputComponent,
    AutocompleteComponent,
    AppValidatorDirective,
    AsyncPipe,
    AppDatePickerComponent,
    SwitchboxComponent,
    NgTemplateOutlet,
  ],
})
export class GECVAuthorComponent extends BaseSection implements OnInit, OnDestroy {
  public sectionName = '';
  public fields = [
    'is_author_not_separate',
    'type',
    'inn',
    'full_name_organization',
    'short_name_organization',
    'inn',
    'ogrn',
    'legal_address_street',
    'post_address_street',
    'email',
    'phone',
    'surname',
    'name',
    'patronymic',
    'position',
    'passport',
  ];
  public relations = '';
  public useValidationRules = true;

  public phonePattern = {
    0: { pattern: new RegExp('[0-9]') },
    7: { pattern: new RegExp('7') },
  };

  validatePhone(control: UntypedFormControl) {
    const phone = /^\+7\(\d{3}\)\d{3}\-\d{2}\-\d{2}$/;
    return { message: !control.value || phone.test(control.value) ? null : 'Неверный телефон' };
  }

  public org_okopf_code = new UntypedFormControl(null);

  readonly GECVAuthorType = GECVAuthorType;

  public defaultValue = {
    type: 'legal',
    is_author_not_separate: true,
  };

  public loading$ = new BehaviorSubject(false);

  readonly fieldsToClear = [
    'inn',
    'full_name_organization',
    'short_name_organization',
    'inn',
    'ogrn',
    'legal_address_street',
    'post_address_street',
    'email',
    'phone',
    'surname',
    'name',
    'patronymic',
    'position',
    'passport',
  ];

  readonly loaded$ = this.isDataLoaded$.pipe(
    distinctUntilChanged(),
    filter(x => x),
  );

  readonly form$ = this.loaded$.pipe(
    switchMap(() => {
      return this.formLoaded$.pipe(
        filter(x => x?.isLoaded),
        map(({ form }) => form),
      );
    })
  );

  get type(): GECVAuthorType {
    return this.reportForm.value.type;
  }

  get is_author_not_separate(): boolean {
    return this.reportForm.value.is_author_not_separate;
  }

  readonly clearForm$ = this.form$.pipe(
    switchMap(form => {
      let stream$!: Observable<any>;

      const typeControl = form.controls.type;
      const isAuthorNotSeparateControl = form.controls.is_author_not_separate;

      if (form.disabled || typeControl.disabled || isAuthorNotSeparateControl.disabled) {
        stream$ = EMPTY;
      } else {
        const typeStream$ = typeControl.valueChanges.pipe(
          distinctUntilChanged(),
          tap(() => this.clearControls(this.fieldsToClear)),
        );
        const isAuthorNotSeparateStream$ = isAuthorNotSeparateControl.valueChanges.pipe(
          distinctUntilChanged(),
          tap(() => this.clearControls(this.fieldsToClear)),
        );

        stream$ = merge(typeStream$, isAuthorNotSeparateStream$);
      }

      return stream$;
    })
  );

  public additionalTitleButtons: DetailsToolbarActions[] = [
    {
      title: 'Взять из профиля',
      action: () => this.fetchFromProfile(),
      isDisabled: () => false,
      isShowed: () => true,
      icon: null,
      tooltip: '',
      loading$: this.loading$,
    },
  ];

  constructor(
    public baseSectionFacade: BaseSectionFacade,
    public route: ActivatedRoute,
    public router: Router,
    public treeHelperService: TreeHelperService,
    public cdr: ChangeDetectorRef,
  ) {
    super(baseSectionFacade, route, router, treeHelperService, cdr);
  }

  ngOnInit() {
    super.ngOnInit();

    setInterval(() => {
      console.log(this.reportForm.value.type);
    }, 1000);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private clearControls(names: string[]) {
    names.forEach(n => {
      this.reportForm.controls[n]?.reset();
      this.reportForm.controls[n]?.markAsDirty();
      this.reportForm.controls[n]?.updateValueAndValidity();
    })
  }

  public fetchFromProfile() {
    this.loading$.next(true);

    this.subscriptions.push(
      this.baseSectionFacade.authenticationService
        .fetchProfile()
        .pipe(finalize(() => this.loading$.next(false)))
        .subscribe((profile) => {
          this.fields.forEach((key) => this.setControlValue(this.reportForm, key, null));

          const org_type = profile?.organization?.type?.toLowerCase();
          this.setControlValue(this.reportForm, 'type', GECVAuthorType[org_type] || org_type);
          this.setControlValue(this.reportForm, 'inn', profile?.organization?.inn);
          this.setControlValue(this.reportForm, 'ogrn', profile?.organization?.ogrn);
          this.setControlValue(this.reportForm, 'short_name_organization', profile?.organization?.short_name);
          this.setControlValue(this.reportForm, 'full_name_organization', profile?.organization?.full_name);

          this.setControlValue(this.reportForm, 'legal_address_street', profile?.organization?.legal_address_address);
          this.setControlValue(this.reportForm, 'post_address_street', profile?.organization?.legal_address_address);

          this.loading$.next(false);
        }),
    );
  }
}
