import { TableType } from 'src/app/providers/_enum';
import { MapItemPoint } from 'src/app/providers/_interfaces/geo.interface';
import { IReportStatus } from 'src/app/providers/_interfaces/report.interface';
import { IStateServices } from 'src/app/providers/_interfaces/state.services.interface';
import { RpnUser, IUser } from 'src/app/providers/_interfaces/user.interface';

export enum ERequestExtended {
  onv_request_inclusion_v2 = 'onv_request_inclusion_v2',
  onv_request_inclusion = 'onv_request_inclusion',
  onv_request_edit_v2 = 'onv_request_edit_v2',
  onv_request_edit = 'onv_request_edit',
  onv_request_adjust_v2 = 'onv_request_adjust_v2',
}

export enum ERequestModel {
  dkt_request = 'Rpn\\Services\\Dkt\\Models\\DktRequest',
  twotp_request_reclamation = 'Rpn\\Services\\Twotp\\Models\\TwotpRequestReclamation',
  twotp_request_greenhouse_gases = 'Rpn\\Services\\Twotp\\Models\\TwotpRequestGreenhouse',
  twotp_request_air = 'Rpn\\Services\\Twotp\\Models\\TwotpRequestAir',
  twotp_request_waste = 'Rpn\\Services\\Twotp\\Models\\TwotpRequestWaste',
  dfr_request = 'Rpn\\Services\\DisposalFacilitiesReport\\Models\\DfrRequest',
  dfr2022_request = 'Rpn\\Services\\DisposalFacilitiesReport\\Models\\Dfr2022Request',
  dvos = 'Rpn\\Services\\Dvos\\Models\\Dvos',
  cites_certificate_import = 'Rpn\\Services\\Cites\\Models\\CitesPermit',
  dpnvos = 'Rpn\\Services\\PaymentNvos\\Models\\Dpnvos',
  dpnvos611 = 'Rpn\\Services\\PaymentNvos\\Models\\Dpnvos611',
  utilization_request = 'Rpn\\Services\\Utilization\\Models\\UtilizationRequest',
  self_utilization_act = 'Rpn\\Services\\SelfUtilization\\Models\\SelfUtilizationAct',
  utilization_weight_experiment = 'Rpn\\Services\\Rop\\Models\\UtilizationWeightExperiment',
  utilization_weight_act = 'Rpn\\Services\\Rop\\Models\\UtilizationWeightAct',

  onv_request_inclusion = 'Rpn\\Services\\Onv\\Models\\OnvRequestInclusion',
  onv_request_inclusion_v2 = 'Rpn\\Services\\Onv\\Models\\OnvRequestInclusionV2',
  onv_request_adjust_v2 = 'Rpn\\Services\\Onv\\Models\\OnvRequestAdjustV2',
  onv_request_edit_v2 = 'Rpn\\Services\\Onv\\Models\\OnvRequestEditV2',
  onv_request_edit = 'Rpn\\Services\\Onv\\Models\\OnvRequestEdit',
  onv_request_correction = 'Rpn\\Services\\Onv\\Models\\OnvRequestCorrection',
  onv_request_exclusion = 'Rpn\\Services\\Onv\\Models\\OnvRequestExclusion',

  groro_request_inclusion = 'Rpn\\Services\\Groro\\Models\\GroroRequestInclusion',
  groro_request_actualization = 'Rpn\\Services\\Groro\\Models\\GroroRequestActualization',
  groro_request_exclusion = 'Rpn\\Services\\Groro\\Models\\GroroRequestExclusion',
  groro_request_update = 'Rpn\\Services\\Groro\\Models\\GroroRequestUpdate',

  ecocharge_request = 'Rpn\\Services\\Ecocharge\\Models\\EcochargeRequest',

  license_request_issue = 'Rpn\\Services\\LicenseActivityWaste\\Models\\LicenseRequestIssue',
  license_request_correction = 'Rpn\\Services\\LicenseActivityWaste\\Models\\LicenseRequestCorrection',
  license_request_writeout = 'Rpn\\Services\\LicenseActivityWaste\\Models\\LicenseRequestWriteout',
  request_department_writeout = 'Rpn\\Services\\LicenseActivityWaste\\Models\\LicenseRequestDepartmentWriteout',
  license_request_replace = 'Rpn\\Services\\LicenseActivityWaste\\Models\\LicenseRequestReplace',
  license_request_termination = 'Rpn\\Services\\LicenseActivityWaste\\Models\\LicenseRequestTermination',
  request_forced_termination = 'Rpn\\Services\\LicenseActivityWaste\\Models\\LicenseRequestForcedTermination',
  license_request_insertion = 'Rpn\\Services\\LicenseActivityWaste\\Models\\LicenseRequestInsertion',
  license_request_modification = 'Rpn\\Services\\LicenseActivityWaste\\Models\\LicenseRequestModification',

  ker_request_issue = 'Rpn\\Services\\Ker\\Models\\KerRequestIssue',
  pek_request = 'Rpn\\Services\\Pek\\Models\\PekRequest',
  pek_report = 'Rpn\\Services\\PekReport\\Models\\PekReport',

  dpnvos_revise_request = 'Rpn\\Services\\Payments\\Models\\DpnvosReviseRequest',
  dpnvos_refund_request = 'Rpn\\Services\\Payments\\Models\\DpnvosRefundRequest',
  requisites_change_request = 'Rpn\\Services\\Payments\\Models\\RequisitesChangeRequest',
  nvos_offset_request = 'Rpn\\Services\\Payments\\Models\\NvosOffsetRequest',

  eek_petition = 'Rpn\\Services\\Eek\\Models\\EekPetitionForCertificate',
  eek_correction = 'Rpn\\Services\\Eek\\Models\\EekPetitionForCorrection',
  eek_duplicate = 'Rpn\\Services\\Eek\\Models\\EekPetitionForDuplicate',
  eek_resolutions = 'Rpn\\Services\\Eek\\Models\\EekCertificateImportIssue',
  gee_request_issue = 'Rpn\\Services\\Gee\\Models\\GeeRequestIssue',
  gee_estimated_costs_validation = 'Rpn\\Services\\Gee\\Models\\GeeEstimatedCostsValidation',
  gee_events_estimated_costs_validation = 'Rpn\\Services\\Gee\\Models\\CostsEventsValidation',
  waste_class_assignment_issue = 'Rpn\\Services\\NvosWasteCertification\\Models\\NvosWasteClassAssignmentIssue',
  animal_registry = 'Rpn\\Services\\Animals\\Models\\AnimalsReestrRecord',

  knd_inspection_request = 'Rpn\\Services\\Knd\\Models\\KndInspectionRequest',

  fgen_recult = 'Rpn\\Services\\Reestr\\Models\\FgenRecult',

  fgen_object = 'Rpn\\Services\\Reestr\\Models\\FgenObject',
  fggn_object = 'Rpn\\Services\\Reestr\\Models\\FggnObject',
  fgzn_object = 'Rpn\\Services\\Reestr\\Models\\FgznObject',
  fgohn_object = 'Rpn\\Services\\Reestr\\Models\\FgohnObject',
  fgotn_object = 'Rpn\\Services\\Reestr\\Models\\FgotnObject',
  fglesn_object = 'Rpn\\Services\\Reestr\\Models\\FglesnObject',
  fgan_object = 'Rpn\\Services\\Reestr\\Models\\FganObject',
  fgahn_object = 'Rpn\\Services\\Reestr\\Models\\FgahnObject',

  ancr_object = 'Rpn\\Services\\Reestr\\Models\\AncrObject',
  eroi_object = 'Rpn\\Services\\Reestr\\Models\\EroiObject',
  recycler_inclusion = 'Rpn\\Services\\Reestr\\Models\\RecyclerInclusion',
  recycler_actualization = 'Rpn\\Services\\Reestr\\Models\\RecyclerActualization',
  atex_object = 'Rpn\\Services\\Reestr\\Models\\AtexObject',
  fkko_object = 'Rpn\\Services\\Reestr\\Models\\FkkoObject',
  commission_object = 'Rpn\\Services\\Reestr\\Models\\CommissionObject',

  ozon_depleting_registry_entry = 'Rpn\\Services\\OzonDepletingRegistry\\Models\\OzonDepletingRegistryEntry',
  addcharge = 'Rpn\\Services\\PaymentNvos\\Models\\Addcharge',

  eco_refund_request = 'Rpn\\Services\\Payments\\Models\\EcoRefundRequest',
  eco_revise_request = 'Rpn\\Services\\Payments\\Models\\EcoReviseRequest',
  eco_offset_request = 'Rpn\\Services\\Payments\\Models\\EcoOffsetRequest',
  expertise_request = 'Rpn\\Services\\Reestr\\Models\\ExpertiseRequest',
  expense_reimbursement = 'Rpn\\Services\\Reestr\\Models\\ExpenseReimbursement',
  termination_request = 'Rpn\\Services\\Reestr\\Models\\TerminationRequest',
  rosnedra_notice = 'Rpn\\Services\\Reestr\\Models\\RosnedraNotice',
  self_exam = 'Rpn\\Services\\Knd\\Models\\SelfExam',
}

export const ERequestModelToType = {
  [ERequestModel.onv_request_exclusion]: TableType.onv_request_exclusion,
  [ERequestModel.onv_request_correction]: TableType.onv_request_correction,
  [ERequestModel.onv_request_edit]: TableType.onv_request_edit,
  [ERequestModel.onv_request_edit_v2]: TableType.onv_request_edit_v2,
  [ERequestModel.onv_request_inclusion]: TableType.onv_request_inclusion,
  [ERequestModel.onv_request_inclusion_v2]: TableType.onv_request_inclusion_v2,
  [ERequestModel.onv_request_adjust_v2]: TableType.onv_request_adjust_v2,

  [ERequestModel.twotp_request_reclamation]: TableType.twotp_request_reclamation,
  [ERequestModel.twotp_request_air]: TableType.twotp_request_air,
  [ERequestModel.twotp_request_waste]: TableType.twotp_request_waste,
  [ERequestModel.twotp_request_greenhouse_gases]: TableType.twotp_request_greenhouse_gases,

  [ERequestModel.dpnvos]: TableType.dpnvos,
  [ERequestModel.dpnvos611]: TableType.dpnvos611,
  [ERequestModel.dvos]: TableType.dvos,

  [ERequestModel.dkt_request]: TableType.dkt_request,
  [ERequestModel.dfr_request]: TableType.dfr_request,
  [ERequestModel.dfr2022_request]: TableType.dfr2022_request,
  [ERequestModel.utilization_request]: TableType.utilization_request,
  [ERequestModel.ecocharge_request]: TableType.ecocharge_request,
  [ERequestModel.self_utilization_act]: TableType.self_utilization_act,
  [ERequestModel.utilization_weight_experiment]: TableType.utilization_weight_experiment,
  [ERequestModel.utilization_weight_act]: TableType.utilization_weight_act,

  [ERequestModel.license_request_issue]: TableType.license_request_issue,
  [ERequestModel.license_request_correction]: TableType.license_request_correction,
  [ERequestModel.license_request_writeout]: TableType.license_request_writeout,
  [ERequestModel.request_department_writeout]: TableType.request_department_writeout,
  [ERequestModel.license_request_replace]: TableType.license_request_replace,
  [ERequestModel.license_request_termination]: TableType.license_request_termination,
  [ERequestModel.request_forced_termination]: TableType.request_forced_termination,
  [ERequestModel.license_request_insertion]: TableType.license_request_insertion,
  [ERequestModel.license_request_modification]: TableType.license_request_modification,

  [ERequestModel.ker_request_issue]: TableType.ker_request_issue,
  [ERequestModel.pek_request]: TableType.pek_request,
  [ERequestModel.pek_report]: TableType.pek_report,

  [ERequestModel.dpnvos_revise_request]: TableType.dpnvos_revise_request,
  [ERequestModel.dpnvos_refund_request]: TableType.dpnvos_refund_request,
  [ERequestModel.requisites_change_request]: TableType.requisites_change_request,
  [ERequestModel.nvos_offset_request]: TableType.nvos_offset_request,

  [ERequestModel.eek_petition]: TableType.eek_petition,
  [ERequestModel.eek_correction]: TableType.eek_correction,
  [ERequestModel.eek_duplicate]: TableType.eek_duplicate,
  [ERequestModel.eek_resolutions]: TableType.eek_resolutions,
  [ERequestModel.cites_certificate_import]: TableType.cites_certificate_import,
  [ERequestModel.gee_request_issue]: TableType.gee_request_issue,
  [ERequestModel.gee_estimated_costs_validation]: TableType.gee_estimated_costs_validation,
  [ERequestModel.gee_events_estimated_costs_validation]: TableType.gee_events_estimated_costs_validation,
  [ERequestModel.groro_request_inclusion]: TableType.groro_request_inclusion,
  [ERequestModel.groro_request_actualization]: TableType.groro_request_actualization,
  [ERequestModel.groro_request_exclusion]: TableType.groro_request_exclusion,
  [ERequestModel.groro_request_update]: TableType.groro_request_update,
  [ERequestModel.waste_class_assignment_issue]: TableType.waste_class_assignment_issue,

  [ERequestModel.animal_registry]: TableType.animal_registry,

  [ERequestModel.knd_inspection_request]: TableType.knd_inspection_request,

  [ERequestModel.fgen_recult]: TableType.fgen_recult,

  [ERequestModel.fgen_object]: TableType.fgen_object,
  [ERequestModel.fggn_object]: TableType.fggn_object,
  [ERequestModel.fgzn_object]: TableType.fgzn_object,
  [ERequestModel.fgohn_object]: TableType.fgohn_object,
  [ERequestModel.fgotn_object]: TableType.fgotn_object,
  [ERequestModel.fglesn_object]: TableType.fglesn_object,
  [ERequestModel.fgan_object]: TableType.fgan_object,
  [ERequestModel.fgahn_object]: TableType.fgahn_object,

  [ERequestModel.ancr_object]: TableType.ancr_object,
  [ERequestModel.eroi_object]: TableType.eroi_object,
  [ERequestModel.recycler_inclusion]: TableType.recycler_inclusion,
  [ERequestModel.recycler_actualization]: TableType.recycler_actualization,
  [ERequestModel.atex_object]: TableType.atex_object,
  [ERequestModel.fkko_object]: TableType.fkko_object,
  [ERequestModel.commission_object]: TableType.commission_object,

  [ERequestModel.addcharge]: TableType.addcharge,

  [ERequestModel.eco_refund_request]: TableType.eco_refund_request,
  [ERequestModel.eco_revise_request]: TableType.eco_revise_request,
  [ERequestModel.eco_offset_request]: TableType.eco_offset_request,
  [ERequestModel.expertise_request]: TableType.expertise_request,
  [ERequestModel.expense_reimbursement]: TableType.expense_reimbursement,
  [ERequestModel.termination_request]: TableType.termination_request,
  [ERequestModel.rosnedra_notice]: TableType.rosnedra_notice,
  [ERequestModel.self_exam]: TableType.self_exam,
};

export const EPnvosModelToQueryType = {
  'Rpn\\Services\\PaymentNvos\\Models\\PaymentNvosRequest': 'payment_nvos_request',
  'Rpn\\Services\\PaymentNvos\\Models\\PaymentNvosRequestCorrection': 'payment_nvos_request_correction',
  'Rpn\\Services\\PaymentNvos\\Models\\PaymentNvosRequestKsv': 'payment_nvos_request_ksv',
  'Rpn\\Services\\PaymentNvos\\Models\\PaymentNvosRequestKsvTotal': 'payment_nvos_request_ksv_total',
};

export interface IRequest {
  id: number;
  number: string;
  date: string;
  is_complete: boolean;
  result_comment: string;
  applicant_organization_id: string;
  geo: MapItemPoint;
  accepted_at?: string;
  updated_at?: string;
  sent_at?: string;
  created_at?: string;
  status_id?: number;
  status?: IReportStatus;
  state_service_id?: number;
  state_service?: IStateServices;
  type?: string;
  original?: any;
  changes?: any;
  user?: RpnUser;
  doer?: IUser;
  status_log?: any;
  signed_http_request_id?: string;
  signs_count?: number;
  emission_object?: any;
  notification_attachment_file?: any;
  notification_comment?: any;
  advanced_documents_comment?: any;
}

export interface FilesForOldSystem {
  xml: string;
  pdf: string;
  'pdf.cms': string;
}
export interface IFilesObject {
  path: string;
  fileName: string;
}
