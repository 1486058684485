import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { Component, Input, Output, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownDirective, ModalDirective, DropdownModule, ButtonsModule, ModalModule, InputsModule } from 'ng-uikit-pro-standard';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { switchMap, debounceTime, tap, map, startWith, catchError } from 'rxjs/operators';
import { ExpansionPanelComponent } from 'src/app/components/expansion-panel/expansion-panel.component';
import { NoContentComponent } from 'src/app/components/no-content/no-content.component';
import { AppIconComponent } from 'src/app/modules/app-icon/app-icon.component';
import { ClickOutsideDirective } from 'src/app/providers/_directives/click-outside/click-outside.directive';
import {
  ICurrentTemplate,
  IAutocompleteParams,
  IAutocompleteComplete,
} from 'src/app/providers/_interfaces/autocomplete-template.interface';
import { IPagination } from 'src/app/providers/_interfaces/pagination.interface';
import { SvcRestService } from 'src/app/providers/_services/svc.rest.service';
import { ToastrService } from 'src/app/providers/_services/toastr.service';
import { deepClone } from 'src/app/providers/_utils/utils';

@Component({
  selector: 'app-template-button',
  templateUrl: './template-button.component.html',
  styleUrls: ['./template-button.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    DropdownModule,
    ClickOutsideDirective,
    AppIconComponent,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    NgClass,
    ButtonsModule,
    ModalModule,
    ExpansionPanelComponent,
    InputsModule,
    NoContentComponent,
    AsyncPipe,
  ],
})
export class TemplateButtonComponent implements OnDestroy {
  @Input() currentValue: ICurrentTemplate;
  @Input() isHidded: boolean;

  private _groupTag = 'test_group';
  get groupTag(): string {
    return this._groupTag;
  }
  @Input() set groupTag(tag: string) {
    this._groupTag = tag;
  }

  private _formTag = 'test_form';
  get formTag(): string {
    return this._formTag;
  }
  @Input() set formTag(tag: string) {
    this._formTag = tag;
  }

  private _fieldTag = 'test_form';
  get fieldTag(): string {
    return this._fieldTag;
  }
  @Input() set fieldTag(tag: string) {
    this._fieldTag = tag;
  }

  @Input() fieldLabel: string;

  @ViewChild('selectTemplateModal') selectTemplateModal: ModalDirective;
  @ViewChild('deleteModal') deleteModal: ModalDirective;
  @ViewChild('bsDropdownMenu') bsDropdownMenu: BsDropdownDirective;

  @Output() selectItemValueEmit = new EventEmitter();

  subscriptions: Subscription[] = [];
  isLoaded = false;
  selectTemplate: IAutocompleteComplete = null;
  editTemplate: IAutocompleteComplete = null;
  params$: Subject<IAutocompleteParams> = new Subject();
  searchControl: UntypedFormControl = new UntypedFormControl('');
  isNewTemplate = false;

  autocompleteList$: Observable<IAutocompleteComplete[]> = combineLatest([
    this.params$.pipe(debounceTime(200)),
    this.searchControl.valueChanges.pipe(
      startWith(''),
      tap((searchText) => {
        this.isLoaded = false;
        if (searchText) {
          this.selectTemplate = null;
          this.editTemplate = null;
        }
      }),
      debounceTime(1000),
    ),
  ]).pipe(
    switchMap(([params, search]) =>
      this.svc.httpGetWithCache('/svc/autocomplete/api/complete', { params, ...(search ? { filters: { search } } : {}) }),
    ),
    catchError(() => of(null)),
    map((res: IPagination<IAutocompleteComplete>) => res?.data || []),
    tap(() => (this.isLoaded = true)),
  );

  constructor(
    private svc: SvcRestService,
    private toaster: ToastrService,
  ) {}

  ngOnDestroy(): void {
    this.subscriptions?.forEach((s) => s?.unsubscribe());
  }

  openModalTemplate() {
    this.bsDropdownMenu.hide();
    this.isLoaded = false;
    this.selectTemplateModal.show();
  }

  updateParams(needUpdateParams: boolean, selectTemplate?: IAutocompleteComplete, editTemplate?: IAutocompleteComplete) {
    if (needUpdateParams) {
      this.isLoaded = false;

      this.params$.next({
        'tags[group]': this._groupTag,
        'tags[form]': this._formTag,
        'tags[field]': this._fieldTag,
      });
    } else {
      this.searchControl.setValue('');
    }

    if (selectTemplate !== void 0) this.selectTemplate = selectTemplate;
    if (editTemplate !== void 0) this.editTemplate = editTemplate ? deepClone(editTemplate) : null;
  }

  createTemplate(): void {
    this.isNewTemplate = true;
    this.isLoaded = false;
    this.bsDropdownMenu.hide();

    const body = { field: { [this._fieldTag]: this.currentValue } };
    const params: IAutocompleteParams = {
      'tags[group]': this._groupTag,
      'tags[form]': this._formTag,
    };

    this.subscriptions.push(
      this.svc
        .putByUrl('/svc/autocomplete/api/complete/', body, { params })
        .pipe(catchError(() => of(null)))
        .subscribe((res: IPagination<IAutocompleteComplete>) => {
          this.openModalTemplate();
          this.updateParams(true, res?.data?.[0], res?.data?.[0]);
        }),
    );
  }

  updateTemplate(template: IAutocompleteComplete): void {
    this.isLoaded = false;
    this.bsDropdownMenu.hide();

    const { id, content } = template;

    this.subscriptions.push(
      this.svc
        .patchByUrl(`/svc/autocomplete/api/complete/${id}`, content)
        .pipe(catchError(() => of(null)))
        .subscribe(() => {
          this.updateParams(true, { ...this.selectTemplate, ...template }, null);
          this.isNewTemplate = false;
        }),
    );
  }

  deleteTemplate(template: IAutocompleteComplete): void {
    this.isLoaded = false;
    this.bsDropdownMenu.hide();
    this.deleteModal.hide();
    this.selectTemplateModal.show();

    const { field, id } = template || {};
    const params: IAutocompleteParams = !id
      ? {
          'tags[group]': this._groupTag,
          'tags[form]': this._formTag,
          ...(field ? { 'tags[field]': field } : {}),
        }
      : {};

    this.subscriptions.push(
      this.svc
        .deleteByUrl(`/svc/autocomplete/api/complete/${id || ''}`, { params })
        .pipe(catchError(() => of(null)))
        .subscribe((res: { success: string }) => {
          if (res?.success) this.toaster.success('', id ? 'Шаблон удален' : 'Шаблоны удалены');
          this.updateParams(true, null, null);
        }),
    );
  }

  clearTemplate(template: IAutocompleteComplete): void {
    template.content = { caption: '', value: '' };
  }

  applyTemplate(template: IAutocompleteComplete): void {
    this.selectTemplateModal.hide();
    this.bsDropdownMenu.hide();
    this.selectItemValueEmit.emit(template.content);
    this.toaster.success('', 'Шаблон подставлен');
  }

  selectTemplateClick(template: IAutocompleteComplete): void {
    if (this.selectTemplate?.id === template.id) {
      this.selectTemplate = null;
    } else {
      this.selectTemplate = template;
    }
    this.editTemplate = null;
  }

  editTemplateClick(template: IAutocompleteComplete): void {
    this.isNewTemplate = false;
    this.editTemplate = this.editTemplate?.id === template.id ? null : deepClone(template);
  }

  isDeleteAll = false;

  deleteTemplateClick(template: IAutocompleteComplete, isDeleteAll?: boolean): void {
    this.selectTemplate = template;

    isDeleteAll ? this.isDeleteAll = true : this.isDeleteAll = false;

    if (template) {
      this.selectTemplateModal.hide();
      this.deleteModal.show();
    } else {
      this.deleteModal.hide();
      this.isDeleteAll = false;
      this.selectTemplateModal.show();
    }
  }

  closeModal() {
    this.selectTemplateModal.hide();
    this.editTemplate = null;
    this.selectTemplate = null;
    this.isNewTemplate = false;
  }
}
