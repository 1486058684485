<ng-container *ngIf="!isHidded">
  <ng-container *ngIf="{autocompleteList: (autocompleteList$ | async)} as state;">

    <div #bsDropdownMenu
      (appClickOutside)="bsDropdownMenu.hide()"
      (isOpenChange)="updateParams($event)"
      class="dropdown d-flex justify-content-end pl-3" mdbDropdown
    >
      <button mdbDropdownToggle type="button" class="dropdown-toggle rpn-button rpn-button_sm rpn-button_square rpn-button_transparent">
        <app-icon #icon [name]="icon.IconsSet.templateButton" width="32px" height="32px"></app-icon>
      </button>

      <div class="dropdown-menu dropdown-menu-right">

        <input [formControl]="searchControl" type="search" placeholder="Поиск шаблона"
          class="completer-input form-control mdb-autocomplete mb-0" />

        <ng-container *ngIf="isLoaded; else isLoadingTemplate">
          <ng-container *ngIf="state?.autocompleteList?.length; else templateEmpty">
            <div class="dropdown-item__container">
              <div *ngFor="let template of state?.autocompleteList" class="dropdown-item pb-2"
                (click)="selectTemplateClick(template)"
                [ngClass]="[template?.id === selectTemplate?.id ? 'active' : '']">
                {{template?.content?.caption || template?.content?.value || 'пустой шаблон'}}

                <app-icon *ngIf="template?.id === selectTemplate?.id" #icon [name]="icon.IconsSet.check"
                  width="20px" height="20px"
                  class="rpn-select-check-icon__position">
                </app-icon>
                <!-- <button class="dropdown-item rpn-template_caption__button" type="button" (click)="openModalTemplate()">
                  <span class="icon icon_pencil"></span>
                </button> -->
              </div>
            </div>
          </ng-container>
        </ng-container>

        <div class="d-flex flex-column justify-content-center align-items-center">
          <ng-container *ngIf="state?.autocompleteList?.length">
            <button type="button" mdbBtn
              class="rpn-button rpn-button_primary rpn-button_sm text-nowrap rpn-button__apply"
              (click)="applyTemplate(selectTemplate)"
              [disabled]="!selectTemplate?.id">
              Использовать шаблон
            </button>
          </ng-container>

          <ng-container *ngIf="!(state?.autocompleteList?.length || searchControl.value?.length) && isLoaded">
            <button type="button" mdbBtn
              class="rpn-button rpn-button_primary rpn-button_sm text-nowrap rpn-button__apply"
              (click)="createTemplate()">
              <span>Создать шаблон</span>
            </button>
          </ng-container>

          <ng-container *ngIf="(state?.autocompleteList?.length || searchControl.value?.length) && isLoaded">
            <button type="button"
              class="dropdown-item text-center rpn-button__manage"
              (click)="openModalTemplate()">
              <span class="rpn-txt bold">
                <app-icon #icon [name]="icon.IconsSet.templateManagement" height="16px"></app-icon>
                Управление шаблонами
              </span>
            </button>
          </ng-container>
        </div>
      </div>
    </div>

    <div mdbModal
      #selectTemplateModal="mdbModal"
      [config]="{backdrop: 'static', keyboard: false}"
      class="modal fade"
      tabindex="-1"
      role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
              <span aria-hidden="true"></span>
            </button>

            <div class="w-100 text-center">
              <h4 class="modal-title w-100">
                {{
                  isNewTemplate ? 'Добавление шаблона' : editTemplate
                                ? 'Редактирование шаблона' : (state?.autocompleteList?.length > 1
                                ? 'Список шаблонов' : 'Шаблон') +  ' для поля "' + fieldLabel + '"'
                }}
              </h4>
            </div>
          </div>

          <div *ngIf="selectTemplateModal.isShown" class="modal-body rpn-modal-body">
            <!-- <input [formControl]="searchControl" type="search" placeholder="Поиск шаблона" class="completer-input form-control mdb-autocomplete mt-2 mb-0" /> -->

            <div *ngIf="isLoaded; else isLoadingTemplate" class="rpn-modal-body__container">
              <ng-container *ngIf="state?.autocompleteList?.length; else templateEmpty">
                <ng-container *ngFor="let template of state?.autocompleteList">

                  <div *ngIf="editTemplate ? (editTemplate.id === template.id) : true" class="pb-2">
                    <app-expansion-panel
                      class="profile__expansion-panel"
                      [title]="('' + (template?.content?.caption || '')) || 'пустой шаблон'"
                      [isOpened]="selectTemplate?.id === template?.id"
                      (isToggled)="selectTemplate = $event ? template : null;"
                      [disabled]="!!editTemplate"
                      [contentIsLoaded]="true">

                      <div *ngIf="template.id === editTemplate?.id; else viewTemplate" class="w-100">
                        <div class="position-relative mt-4">
                          <label for="caption" class="active">Название шаблона</label>
                          <input mdbInput id="caption" [(ngModel)]="editTemplate.content.caption" style="width: -webkit-fill-available;">
                        </div>

                        <div class="position-relative mt-4">
                          <label for="value" class="active">Текст шаблона</label>
                          <textarea mdbInput id="value" [(ngModel)]="editTemplate.content.value" [rows]="2" style="width: -webkit-fill-available;"></textarea>
                        </div>


                        <div class="d-flex align-items-center justify-content-between mt-3">
                          <div class="d-flex">
                            <button type="button" mdbBtn color="link"
                              class="rpn-button rpn-button_bordered rpn-button_bordered-green rpn-button_sm rpn-button_save"
                              (click)="updateTemplate(editTemplate)">
                              Сохранить
                            </button>

                            <button type="button" mdbBtn color="link"
                              class="rpn-button rpn-button_bordered rpn-button_bordered-dark rpn-button_sm"
                              (click)="clearTemplate(editTemplate)">
                              Очистить
                            </button>
                          </div>

                          <button type="button" mdbBtn color="link"
                            class="rpn-button rpn-button_bordered rpn-button_bordered-dark rpn-button_sm"
                            (click)="editTemplateClick(editTemplate)">
                            Отменить
                          </button>
                        </div>
                      </div>

                      <ng-template #viewTemplate>
                        <div class="w-100">
                          {{template.content.value}}
                        </div>

                        <div class="d-flex align-items-center justify-content-between mt-3">
                          <button *ngIf="template.id !== editTemplate?.id" type="button" mdbBtn color="link"
                            class="rpn-button rpn-button_bordered rpn-button_bordered-green rpn-button_sm ml-0"
                            (click)="applyTemplate(template)">
                            <app-icon #icon [name]="icon.IconsSet.check" width="20px" height="20px"></app-icon>
                            <span class="ml-3">Использовать шаблон</span>
                          </button>

                          <div class="d-flex">
                            <button type="button" mdbBtn color="link"
                              class="rpn-button rpn-button_bordered rpn-button_bordered-dark rpn-button_sm"
                              (click)="editTemplateClick(template)">
                              <span>Отредактировать шаблон</span>
                            </button>
                            <button type="button" mdbBtn color="link"
                              class="rpn-button rpn-button_bordered rpn-button_bordered-red rpn-button_sm mr-0"
                              (click)="deleteTemplateClick(template)"
                            >
                              <span>Удалить шаблон</span>
                            </button>
                          </div>
                        </div>
                      </ng-template>
                    </app-expansion-panel>
                  </div>

                </ng-container>
              </ng-container>
            </div>
          </div>

          <div *ngIf="!editTemplate" class="modal-footer d-flex justify-content-center">

            <button type="button" mdbBtn
              class="rpn-button rpn-button_primary rpn-button_sm text-nowrap"
              (click)="createTemplate()">
              <span class="icon icon_plus"></span>
              <span>Добавить новый шаблон</span>
            </button>

            <button
              *ngIf="state?.autocompleteList?.length" type="button" mdbBtn color="link"
              class="rpn-button rpn-button_bordered rpn-button_bordered-red rpn-button_sm mr-0"
              (click)="deleteTemplateClick({field: fieldTag, id: null}, true)"
            >
              <span>Удалить все шаблоны</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div mdbModal
      #deleteModal="mdbModal"
      class="modal fade"
      tabindex="-1"
      role="dialog">
      <div class="modal-dialog modal-dialog--sm">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close pull-right" aria-label="Close" (click)="deleteTemplateClick(null)">
              <span aria-hidden="true"></span>
            </button>

            <div class="w-100 text-center">
              <h4 class="modal-title w-100">{{ 'Подтвердите удаление шаблон' + (isDeleteAll ? 'ов' : 'а') }}</h4>
            </div>
          </div>

          <div *ngIf="deleteModal.isShown" class="modal-body">
            {{ (isDeleteAll ? 'Шаблоны будут удалены' : 'Шаблон будет удален') + ' без возможности восстановления.' }}
          </div>

          <div class="modal-footer d-flex justify-content-center">
            <button *ngIf="state?.autocompleteList?.length" type="button" mdbBtn color="link"
              class="rpn-button rpn-button_bordered rpn-button_bordered-red rpn-button_sm mr-0"
              (click)="deleteTemplate(selectTemplate)">
              <span>{{ 'Удалить шаблон' + (isDeleteAll ? 'ы' : '') }}</span>
            </button>
            <button type="button" mdbBtn
              class="rpn-button rpn-button_primary rpn-button_sm text-nowrap"
              (click)="deleteTemplateClick(null)">
              <span>Отменить</span>
            </button>

          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #isLoadingTemplate>
  <div class="m-3">
    <div class="rpn-skeleton form-control h-auto mb-3">&nbsp;</div>
    <div class="rpn-skeleton form-control h-auto mb-3">&nbsp;</div>
    <div class="rpn-skeleton form-control h-auto mb-3">&nbsp;</div>
  </div>
</ng-template>

<ng-template #templateEmpty>
  <app-no-content [fontSize]="'sm'" [searchValue]="searchControl?.value" [withOutBottomBorder]="true">Шаблоны отсутствуют</app-no-content>
</ng-template>
