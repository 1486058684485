import {
  ChangeDetectorRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  Directive,
  HostListener,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Event, NavigationEnd, ParamMap, Params, Router } from '@angular/router';
import { select } from '@ngrx/store';
import { get } from 'lodash';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { BehaviorSubject, Observable, of, Subscription, combineLatest } from 'rxjs';
import {
  filter,
  map,
  shareReplay,
  switchMap,
  tap,
  catchError,
  withLatestFrom,
  debounceTime,
  startWith,
  distinctUntilChanged,
  skipUntil,
} from 'rxjs/operators';
import { UploadFileComponent } from 'src/app/components/upload-file/upload-file.component';
import { BaseSectionFacade, IBaseSectionForm } from 'src/app/modules/details-edit-mode/base-section/base-section.facade';
import { SectionFields } from 'src/app/modules/details-edit-mode/base-section/section-fields.class';
import { DetailsEditModeCardActions } from 'src/app/modules/details-edit-mode/details-edit-mode-card/details-edit-mode-card.interface';
import { ModalShowErrorService } from 'src/app/modules/modal-show-error/services/modal-show-error.service';
import { IAppFieldWithValidation } from 'src/app/providers/_classes/app.form.field.class';
import { APP_ROUTER_PARAMS } from 'src/app/providers/_const/app-router-params.const';
import { StateServiceIdMap } from 'src/app/providers/_const/fgen-object.const';
import { EDocType } from 'src/app/providers/_const/fields.const';
import { EInputType } from 'src/app/providers/_const/input.type.const';
import { DICT, DictKEYS, DictDadataKEYS } from 'src/app/providers/_dictionary';
import { ValidatorsKEYS } from 'src/app/providers/_directives/validator/validators';
import { InputAppend, InputCategory, TableType, OnvosRequestType, Sort } from 'src/app/providers/_enum';
import { INodeTree, ITreeRoute } from 'src/app/providers/_interfaces/common';
import { IEssence } from 'src/app/providers/_interfaces/essence.interface';
import { IEnvironmentTaxableGoods } from 'src/app/providers/_interfaces/payments-managment.response.interface';
import { IRegionFilters } from 'src/app/providers/_interfaces/region';
import { IReportTransition, ISetControlOptions } from 'src/app/providers/_interfaces/report.interface';
import { ERequestExtended } from 'src/app/providers/_interfaces/request.interface';
import { TerritoryOrgType } from 'src/app/providers/_interfaces/territory.org';
import { filterTransitions } from 'src/app/providers/_pipes/filter-transitions.pipe';
import { TreeHelperService } from 'src/app/providers/_services/tree-helper.service';
import { UISectionText } from 'src/app/providers/_text/section';
import { getDirtyValues, resetControls, setControlValue } from 'src/app/providers/_utils/reactive-form.utils';
import { deepClone, plus } from 'src/app/providers/_utils/utils';
import { EssenceSelectors } from 'src/app/store';

interface ITerritoryOrgFilters {
  org_type?: null | string | string[];
  acceptable_state_service_ids?: null | number | number[];
  federal_district_id?: null | number | number[];
  supervisory_territory_org_id?: null | number | number[];
}

@Directive()
export abstract class BaseSection implements OnInit, OnDestroy, IBaseSectionForm, OnChanges {
  abstract fields: string[];
  abstract sectionName: string;
  abstract relations: string;

  public sectionFields = new SectionFields([], this);

  public oldValuesScope = false;
  public useValidationRules = false;
  public useRootRoutesAndForms = false;
  public isDataLoaded = false;
  public isDataLoaded$ = new BehaviorSubject(this.isDataLoaded);
  public cacheKey: string;
  public withOldBody = false;
  public resultCommentKey: string;
  public reportForm$: Observable<UntypedFormGroup>;
  public refetchReportForm$ = new BehaviorSubject<null>(null);
  public InputCategory = InputCategory;
  public InputAppend = InputAppend;
  public EDocType = EDocType;
  public objectId: string;
  public routeParams: { [key: string]: string };
  public treePathBranches: INodeTree[];
  public OnvosRequestType = OnvosRequestType;
  public TableType = TableType;
  public tree: INodeTree | null;
  public Sort = Sort;
  public additionalFields: Partial<{ [key in ERequestExtended]: string[] }> = {};
  public ERequestExtended = ERequestExtended;
  public DictKEYS = DictKEYS;
  public DictDadataKEYS = DictDadataKEYS;
  public ValidatorsKEYS = ValidatorsKEYS;
  public StateServiceIdMap = StateServiceIdMap;
  public TerritoryOrgType = TerritoryOrgType;
  public setControlValue = setControlValue;
  public resetControls = resetControls;
  public routes: any;

  public user = this.baseSectionFacade.authenticationService.user$.getValue();
  public userTerOrg = get(this.user, ['profile', 'organization', 'territory_org'], null);
  public userTerOrgId = (this.userTerOrg && this.userTerOrg.id) || null;

  public templateTags$: Observable<{ groupTag: string; formTag: string }> = this.baseSectionFacade.store.pipe(
    select(EssenceSelectors.selectCurrentRouteParams),
    map(({ type, treePath }) => ({ groupTag: type, formTag: treePath?.join('.') })),
  );

  private readonly _isModalFormView$ = new BehaviorSubject(false);
  readonly isModalFormView$ = this._isModalFormView$.asObservable();

  get territoryOrgFilters(): ITerritoryOrgFilters {
    return {};
  }

  get regionFilters(): IRegionFilters {
    return { id: this.reportForm.controls?.territory_org?.value?.region_ids || null };
  }

  get currentYear() {
    return new Date().getFullYear();
  }

  public submitValidation = false;
  private submitValidationModalRef: MDBModalRef;

  public defaultValue = {};
  public defaultApplyStatus = 'draft';
  public EInputType = EInputType;
  public currentStatusCode = '';
  public currentEssenceType: TableType;
  public currentEssenceId = '';
  public isModalFormView: boolean;
  public isModalFormViewSaveOnlyDirty: boolean;
  public modalTransitionId: string;
  public reportForm: UntypedFormGroup;
  public modalButtons: DetailsEditModeCardActions[] | null;
  public showModalTransition: IReportTransition | null;
  public modalNextStatus: string;
  public transitionModalFormVariant: string;
  public UISectionText = UISectionText;
  public formLoaded$: BehaviorSubject<{ form: UntypedFormGroup; isLoaded: boolean } | null> = new BehaviorSubject<{
    form: UntypedFormGroup;
    isLoaded: boolean;
  } | null>(null);
  public queryParamsChanged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLK = this.baseSectionFacade.isLK;
  public isKSV = this.baseSectionFacade.isKSV;
  @Output() public formValueChange = new EventEmitter();
  @Input() formValue;
  public formValueUpdate$ = new BehaviorSubject<any>(null);
  public formValue$: Observable<any>;
  public DICT = DICT;

  public reportYearsStaticOptions = [];
  public state_service_id: number;

  public currentEssence$ = this.baseSectionFacade.store.pipe(
    select(EssenceSelectors.selectCurrentEssence),
    filter((x) => !!x),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public currentEssenceType$ = this.baseSectionFacade.store.pipe(
    select(EssenceSelectors.selectCurrentEssenceType),
    filter((x) => !!x),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public is_secret$: Observable<boolean> = this.currentEssence$.pipe(
    map((essence) => {
      return !!essence.emission_object?.is_secret;
    }),
  );

  public is_secure$: Observable<boolean> = this.currentEssence$.pipe(
    map((essence) => {
      return !!essence.emission_object?.is_secure;
    }),
  );

  private dirtyControls: { [key: string]: boolean } = {};

  public subscriptions: Subscription[] = [];
  set pushToSubscriptions(s: Subscription) {
    this.subscriptions.push(s);
  }
  public get showTooltipDiff(): boolean {
    return this.currentEssenceType === TableType.onv_request_edit || this.currentEssenceType === TableType.onv_request_correction;
  }

  public get isTO(): boolean {
    return !this.userTerOrg ? false : [TerritoryOrgType.to, TerritoryOrgType.ca].indexOf(this.userTerOrg.org_type) >= 0;
  }

  public formBranch: INodeTree;

  public modalFormSubmit: (data: UntypedFormGroup) => void;

  public selectCanActivateChild$ = this.formLoaded$.pipe(
    map((res) => ({ form: res && res.form })),
    debounceTime(200),
    map(({ form }) => {
      const controls = get(form, ['controls']);
      if (controls) {
        const dirtyFields = Object.keys(controls).filter((key) => controls[key].dirty && controls[key].touched && !controls[key].disabled);
        if (dirtyFields.length) {
          console.log(dirtyFields)
          const res = confirm('У вас есть несохраненные изменения!\nПокинуть страницу?');
          if (res) this.formLoaded$.next(null);

          return res;
        }
      }

      return true;
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  public hasSmevRequest$ = this.currentEssence$.pipe(
    map((essence) => essence && !!essence.smev_order_id),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  public reportYear$ = this.currentEssence$.pipe(
    map((currentEssence) => {
      const report_year = currentEssence.report_year;

      if (report_year) {
        DICT[DictKEYS.environment_taxable_goods_by_report_year] = {
          dictURL: DICT[DictKEYS.environment_taxable_goods].dictURL + '/year/' + report_year,
          dictItemURL: DICT[DictKEYS.environment_taxable_goods].dictItemURL,
          dictionary$: new BehaviorSubject<IEnvironmentTaxableGoods[]>(null),
          loading: false,
          expiryDate: 0,
        };
      }

      return report_year;
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  public routerNavigationEnd$ = this.router.events.pipe(filter((events) => events instanceof NavigationEnd)) as Observable<NavigationEnd>;

  public report_period$ = combineLatest([
    this.reportYear$.pipe(filter((x) => !!x)),
    this.baseSectionFacade.dictionaryService
      .getDictionaryStorage(DICT, DictKEYS.disposal_normativs_period)
      .dictionary$.pipe(filter((x) => !!x?.length)),
  ]).pipe(
    map(([year, dict]) => dict.slice(0).filter((d) => !!d.is_active && year >= d.year_start && year <= d.year_end)),
    filter((x) => !!x?.length),
    map((dicts) => dicts[0]?.id || null),
    filter((x) => !!x),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  @ViewChildren(IAppFieldWithValidation) formFields: QueryList<IAppFieldWithValidation>;
  @ViewChildren(UploadFileComponent) fileUploads: QueryList<UploadFileComponent>;
  @HostListener('window:beforeunload', ['$event']) onWindowBeforeUnload(_event: Event): boolean | Observable<boolean> {
    return this.baseSectionFacade.canActivate();
  }

  constructor(
    public baseSectionFacade: BaseSectionFacade,
    public route: ActivatedRoute,
    public router: Router,
    public treeHelperService: TreeHelperService,
    public cdr: ChangeDetectorRef,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.formValue) {
      this.formValueUpdate$.next(changes.formValue);
    }
  }

  public onUpdateSectionFields() {}

  public ngOnInit(): void {
    this.pushToSubscriptions = this.baseSectionFacade.isLoaded$.pipe(debounceTime(100)).subscribe((isLoaded) => {
      this.isDataLoaded = isLoaded;
      this.isDataLoaded$.next(isLoaded);
      if (isLoaded && this.objectId !== 'new') {
        this.treeHelperService.updateExpandTree$.next(null);
      }
      this.cdr.detectChanges();
    });

    this.pushToSubscriptions = ModalShowErrorService.instance.serverError$.pipe(filter((err) => !!err)).subscribe(() => {
      this.restoreDirtyState(this.reportForm);
      this.baseSectionFacade.isLoaded$.next(true);
    });

    this.pushToSubscriptions = this.route.queryParams
      .pipe(withLatestFrom(this.currentEssence$))
      .subscribe(([queryParams, currentEssence]) => {
        this.isModalFormView = !!queryParams[APP_ROUTER_PARAMS.MODAL_FORM_VIEW];
        this._isModalFormView$.next(!!queryParams[APP_ROUTER_PARAMS.MODAL_FORM_VIEW]);
        this.modalTransitionId = queryParams[APP_ROUTER_PARAMS.MODAL_TRANSITION_ID];
        this.modalButtons = this.isModalFormView ? this.initModalButtons() : [];
        this.initTransition(queryParams, currentEssence);

        this.state_service_id = currentEssence.state_service_id;
        this.queryParamsChanged$.next(true);
        this.cdr.detectChanges();
      });

    this.pushToSubscriptions = this.treeHelperService.tree$.subscribe((tree) => (this.tree = tree));

    this.pushToSubscriptions = this.currentEssence$
      .pipe(filter((essence) => essence?.state_service?.report_periods?.length > 0))
      .subscribe((essence: any) => {
        this.reportYearsStaticOptions = essence.state_service.report_periods.filter((x) => x.is_active);
        this.cdr.detectChanges();
      });

    // this.subscriptions.push(
    //   this.baseSectionFacade.store.pipe(
    //     select(EssenceSelectors.selectCurrentRouteData),
    //     filter(x => !!x?.currentEssence),
    //     debounceTime(500),
    //   ).subscribe(() => this.baseSectionFacade.isLoaded$.next(true))
    // );

    this.reportInit();
    this.submitSuccessSubscribe();
    this.submitErrorSubscribe();
    this.checkReasonSubscribe();
    this.hooksSubscribe();
  }

  public ngOnDestroy(): void {
    if (this.submitValidationModalRef) {
      this.submitValidationModalRef.hide();
    }

    this.subscriptions.forEach((s) => s?.unsubscribe());
    this.baseSectionFacade.isLoaded$.next(false);

    if (this.baseSectionFacade.currentSection === this) {
      this.baseSectionFacade.currentSection = null;
    }
  }

  canActivate(): Observable<boolean> {
    return this.selectCanActivateChild$;
  }

  public extendCurrentObject(currentObject: any): any {
    return currentObject;
  }

  public extendFormGroup(_formGroup: UntypedFormGroup): void {}

  public initModalButtons(): DetailsEditModeCardActions[] {
    return [
      {
        action: () => {
          this.baseSectionFacade.location.back();
        },
        title: 'Отмена',
        color: 'danger',
      },
      {
        action: () => {
          if (this.reportForm.invalid) return;

          if (this.modalFormSubmit) {
            this.modalFormSubmit(this.reportForm);
          } else {
            this.submit(this.reportForm);
          }
        },
        title: 'Подтверждаю',
        isDisabled: () =>
          this.reportForm.statusChanges.pipe(
            startWith(this.reportForm.invalid),
            map(() => this.reportForm.invalid),
          ),
        color: 'success',
      },
    ];
  }

  public fixRoutes(routes: ITreeRoute, essence: IEssence) {
    if (!this.useRootRoutesAndForms) {
      return routes;
    }

    if (!essence || !essence.form || !essence.form.route) {
      return routes;
    }

    return Object.keys(essence.form.route).reduce((obj: any, key: string) => {
      obj[key] = essence.form.route[key] ? essence.form.route[key] : null;
      return obj;
    }, {});
  }

  public fixBranch(branch: INodeTree, essence: IEssence) {
    if (!this.useRootRoutesAndForms) {
      return branch;
    }

    if (!essence || !essence.form || !essence.form.properties) {
      return branch;
    }

    return {
      ...branch,
      identity: essence.form.identity,
    };
  }

  versionMap(mapData: { [key: string]: string }) {
    const keys = Object.keys(mapData);

    for (const key of keys) {
      if (this.version(key)) {
        return mapData[key];
      }
    }

    return null;
  }

  version(ver: string) {
    const verIndex = this.currentEssenceType.length - ver.length;

    return this.currentEssenceType.indexOf(ver) === verIndex;
  }

  fieldIsOn(name: string) {
    return this.fields.includes(name);
  }

  private initTransition(queryParam: Params, currentEssence: IEssence, branch?: INodeTree): void {
    const { transition_id } = queryParam;
    this.showModalTransition = filterTransitions(
      currentEssence?.transitions,
      currentEssence,
      this.baseSectionFacade.authenticationService,
    ).find((t) => {
      return transition_id ? t.id + '' === transition_id + '' : t.properties && t.properties.show_modal_form === branch?.identity;
    });

    this.modalNextStatus = this.showModalTransition?.to_status?.code || null;
    this.transitionModalFormVariant = get(this.showModalTransition, 'properties.modal_form_variant');
  }

  private reportInit(): void {
    const objectId$ = this.route.paramMap.pipe(
      map((paramMap: ParamMap) => paramMap.get('objectId')),
      distinctUntilChanged((a, b) => '' + (a || '') === '' + (b || '')),
    );

    this.reportForm$ = combineLatest([objectId$, this.refetchReportForm$]).pipe(
      tap(() => {
        this.baseSectionFacade.isLoaded$.next(false);
        this.queryParamsChanged$.next(false);
        this.baseSectionFacade.currentSection = this;
      }),
      withLatestFrom(
        this.baseSectionFacade.store.pipe(select(EssenceSelectors.selectCurrentEssence)),
        this.baseSectionFacade.store.pipe(select(EssenceSelectors.selectCurrentEssenceType)),
      ),
      switchMap(([[objectId, _], currentEssence, type]: [[string, null], IEssence, TableType]) => {
        this.objectId = objectId || '';
        this.currentEssenceType = type;

        const additionalFields = this.additionalFields[this.currentEssenceType];
        if (additionalFields) this.fields.push(...additionalFields);

        this.currentEssenceId = currentEssence?.id;

        const { routeParams, branch, treePath, treePathBranches } = this.treeHelperService.restoreRouteParamsFromActiveRoute(
          this.route.snapshot.pathFromRoot,
        );

        if (branch) {
          let operation = branch.canAddChildren ? 'index' : 'show';

          if (('' + this.objectId).indexOf('new') + 1) {
            operation = 'new';
          }

          const branchMeta = this.treeHelperService.getMetaForPath(branch, treePathBranches || []);
          const isEditable = branchMeta ? branchMeta.is_editable : false;

          this.routeParams = routeParams;
          this.routes = this.fixRoutes(branch.route, currentEssence);
          this.formBranch = this.fixBranch(branch, currentEssence);
          this.treePathBranches = treePathBranches;

          return this.baseSectionFacade
            .essenceReactiveFormInit({
              treeId: currentEssence?.id,
              sectionName: this.sectionName,
              fields: this.fields,
              route: this.routes,
              operation,
              treePath,
              routeParams,
              relations: this.relations,
              obj: {},
              defaultValue: this.defaultValue,
              isEditable: isEditable,
              extendCurrentObject: (co) => this.extendCurrentObject(co),
              defaultApplyStatus: this.defaultApplyStatus,
              cacheKey: this.cacheKey,
              oldValuesScope: this.oldValuesScope,
            })
            .pipe(
              tap((res: { form: UntypedFormGroup; isLoaded: boolean }) => {
                if (res.isLoaded) {
                  const deleteQueryParam = this.route.snapshot.queryParamMap.get('delete') || '';
                  if (deleteQueryParam) setTimeout(() => this.deleteObject(), 1000);
                }
              }),
              map((data) => {
                this.resetDirtyState();
                this.baseSectionFacade.loadedService.loadFinish();
                return { form: data.form, isLoaded: data.isLoaded };
              }),
            );
        } else {
          const formData = this.baseSectionFacade.createJsonForReactiveGroup(
            currentEssence?.id,
            this.sectionName,
            this.fields,
            {},
            this.defaultValue,
            {},
            false,
            false,
          );
          const dirtyValue: string[] = [];

          Object.keys(formData).forEach((key) => {
            const obj = formData[key]?.[0];
            if (obj.isDirty) {
              dirtyValue.push(key);
              delete obj.isDirty;
            }
          });

          const formGroup = this.baseSectionFacade.fb.group(formData);
          dirtyValue.forEach((key) => formGroup.controls[key].markAsDirty());

          return of({ form: formGroup, isLoaded: true }).pipe(tap(() => this.resetDirtyState()));
        }
      }),
      tap(({ form }) => this.extendFormGroup(form)),
      tap(({ form, isLoaded }) => {
        this.formLoaded$.next({ form, isLoaded });
        if (isLoaded) {
          this.baseSectionFacade.formDataApplyCopy(form);
          this.baseSectionFacade.isLoaded$.next(isLoaded);
        }
      }),
      map(({ form }) => form),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      }),
    );

    this.pushToSubscriptions = this.baseSectionFacade.currentStatusCode$.subscribe((res) => (this.currentStatusCode = res));

    this.pushToSubscriptions = this.reportForm$
      .pipe(withLatestFrom(this.baseSectionFacade.store.pipe(select(EssenceSelectors.selectCurrentEssence))))
      .subscribe(([reportForm, currentEssence]) => {
        this.reportForm = this.baseSectionFacade.reportForm = reportForm;

        const { branch } = this.treeHelperService.restoreRouteParamsFromActiveRoute(this.route.snapshot.pathFromRoot);
        this.formBranch = this.fixBranch(branch, currentEssence);

        if (this.useValidationRules || this.formBranch?.use_frontend_validation) {
          const validations = this.formBranch?.validation || {};
          const errors = (this.formBranch?.current_errors || []).map((x) => {
            const err = deepClone(x);
            if (err?.message && err.message.indexOf('обязательно для заполнения') >= 0) err.message = 'Обязательное поле';
            return err;
          });

          this.formFields?.forEach((field) => field.setValidators(validations, errors));
        }

        this.cdr.markForCheck();
      });
  }

  public formObs() {
    return this.reportForm$.pipe(
      tap((form) => {
        this.formValue$ = form.valueChanges.pipe(
          startWith(form.value),
          map((value) => {
            return value;
          }),
        );

        const formValueChange$ = this.formValue$.pipe(
          map((value) => {
            this.formValueChange.next(value);
          }),
        );

        const formValueUpdate$ = this.formValueUpdate$.pipe(
          filter((value) => !!value),
          map((value) => {
            const keys = Object.keys(value);

            for (const key of keys) {
              const v = value[key];
              const control = form.controls[key];

              if (control && control.value !== v) {
                control.setValue(v);
              }
            }
          }),
        );

        plus(this, formValueChange$, formValueUpdate$);
      }),
    );
  }

  public hooksSubscribe() {
    const form$ = this.formObs();

    const updateSectionFields$ = this.routerNavigationEnd$.pipe(
      skipUntil(form$),
      map((events) => events.url),
      startWith(window.location.pathname),
      distinctUntilChanged(),
      map(() => this.onUpdateSectionFields()),
    );

    plus(this, form$, updateSectionFields$);
  }

  public checkReasonSubscribe() {
    const pipe = this.baseSectionFacade.detailsEditModeService.initAdvancedTitle(this.currentEssenceType);

    const sub = pipe.subscribe();
    this.subscriptions.push(sub);
  }

  public submit(data: UntypedFormGroup, reload_flag = false, returnBack = false): void {
    const isModalView = this.showModalTransition && this.isModalFormView;
    const isModalCanSaveEmpty = this.submitValidation && this.isModalFormViewSaveOnlyDirty;

    const body = isModalView && !this.isModalFormViewSaveOnlyDirty ? data.value : getDirtyValues(data);

    if (Object.keys(body).length || isModalCanSaveEmpty) {
      if (this.fileUploads.length && this.fileUploads.find((upload) => upload.hasActiveUploads)) {
        if (!confirm('На форме есть не до конца загруженные файлы и они не будут сохранены.\nПродолжить?')) {
          return;
        } else {
          this.fileUploads.forEach((file) => file.cancelUploads());
        }
      }

      if (this.submitValidation && data.invalid) {
        console.log(data)
        const headerTitle = 'Внимание, данные не сохранены!';
        const bodyText = 'Необходимо заполнить все поля без ошибок';
        const openModal = this.baseSectionFacade.modalShowTemplateService.openModal(headerTitle, bodyText, null);

        this.submitValidationModalRef = openModal.modalRef;
        this.pushToSubscriptions = openModal.closeModal$.subscribe();
        return;
      }

      this.baseSectionFacade.loadedService.loadStart();
      this.baseSectionFacade.isLoaded$.next(false);
      this.saveDirtyState(data);

      Object.keys(data.controls).forEach((key) => data.controls[key].markAsUntouched());
      Object.keys(data.controls).forEach((key) => data.controls[key].markAsPristine());

      data.updateValueAndValidity();

      this.baseSectionFacade.updateEssenceSection(
        body,
        this.routes,
        this.routeParams,
        this.objectId,
        reload_flag,
        returnBack,
        this.cacheKey,
        this.withOldBody,
      );
    }
  }

  public addNewObject(path: string = './new'): void {
    setTimeout(() => this.router.navigate([path], { relativeTo: this.route }), 0);
  }

  public deleteObject(cascadeDelete = false): boolean {
    if (this.objectId === 'new') return false;

    if (confirm('Вы действительно хотите удалить?')) {
      this.baseSectionFacade.isLoaded$.next(false);
      this.baseSectionFacade.deleteEssenceObject(this.routes, this.routeParams, cascadeDelete);
      this.objectId = 'new';
      return true;
    } else {
      this.baseSectionFacade.isLoaded$.next(true);
      this.router.navigate(['./'], { relativeTo: this.route });
      return false;
    }
  }

  public setNewStatusInForm(data: UntypedFormGroup, status: string) {
    if (!data?.value?.next_statuses) return;

    const newStatus = data.value.next_statuses.find((s: any) => s.code === status);
    if (newStatus) {
      data.controls.status_id.setValue(newStatus.id);
      data.controls.status_id.markAsDirty();
    }
  }

  public applyStatus(data: UntypedFormGroup, status: string): void {
    if (!data?.value?.next_statuses) return;

    const newStatus = data.value.next_statuses.find((s: any) => s.code === status);
    if (newStatus) {
      const control = new UntypedFormControl(newStatus.id);
      control.markAsDirty();
      this.submit(new UntypedFormGroup({ status_id: control }));
    }
  }

  public setControlObject(form: any, key: string, item: any, options: ISetControlOptions = { onlySelf: true }) {
    this.setControlValue(form, key, item ? { ...item } : null, options);
  }

  public setControlValueSelf(form: any, key: string, value: any, options: ISetControlOptions = { onlySelf: true }) {
    this.setControlValue(form, key, value, options);
  }

  public clearValidators(form: UntypedFormGroup, name: string) {
    if (form && form.controls && form.controls[name]) {
      form.controls[name].clearValidators();
      form.controls[name].clearAsyncValidators();
      form.controls[name].updateValueAndValidity();
    }
  }

  public submitSuccessSubscribe() {
    this.pushToSubscriptions = this.baseSectionFacade.submitSuccessSubscribe().subscribe((action) => {
      if (!this.withOldBody) {
        const body = {};
        const result_comment = this.reportForm?.controls?.result_comment?.value;
        if (result_comment) body['result_comment'] = result_comment;
        this.onSubmitSuccess(body);
        return;
      }

      const result_comment_key = this.resultCommentKey || 'result_comment';
      this.onSubmitSuccess({ [result_comment_key]: get(action, `payload.oldBody.${result_comment_key}`) });
    });
  }

  private submitErrorSubscribe() {
    this.pushToSubscriptions = this.baseSectionFacade.submitErrorSubscribe().subscribe((action) => this.onSubmitError(action));
  }

  public onSubmitSuccess(body?: { [key: string]: any }): void {
    if (this.showModalTransition && this.isModalFormView) {
      this.baseSectionFacade.isLoaded$.next(false);
      this.pushToSubscriptions = this.changeStatus(body)
        .pipe(catchError(() => of(null)))
        .subscribe(() => {
          Object.keys(this.reportForm.controls || {}).forEach(
            (key) => this.reportForm.controls && this.reportForm.controls[key] && this.reportForm.controls[key].markAsUntouched(),
          );
          this.baseSectionFacade.isLoaded$.next(true);
          if (this.baseSectionFacade?.reportForm?.dirty) this.baseSectionFacade.reportForm.markAsPristine();
          this.router.navigate(['./'], { relativeTo: this.route }); // for clear query params
        });
    }
  }

  public onSubmitError(_action: { type: string; payload: { error: any } }): void {}

  private changeStatus(body: { [key: string]: any }, withoutNavigate: boolean = false): Observable<any> {
    const type = TableType[this.tree?.id] || TableType[this.currentEssenceType] || '';
    const essenceId = this.tree?.essenceId || '';
    const statusId = this.showModalTransition?.to_status?.id || 0;

    return this.treeHelperService.changeStatus(this.showModalTransition, type, essenceId, statusId, body, withoutNavigate);
  }

  getRoutesUrl(key: string): string {
    if (!this.routes || !this.routeParams) {
      return '';
    }
    return this.baseSectionFacade.svc.rebuildUrlFromParams(this.routes[key] || '', this.routeParams);
  }

  private saveDirtyState(reportForm: UntypedFormGroup): void {
    Object.keys(reportForm.controls).forEach((key) => (this.dirtyControls[key] = reportForm.controls[key].dirty));
  }

  private restoreDirtyState(reportForm: UntypedFormGroup): void {
    for (const key of Object.keys(this.dirtyControls)) {
      if (this.dirtyControls[key]) {
        reportForm.controls[key].markAsDirty();
      }
    }
  }

  private resetDirtyState(): void {
    this.dirtyControls = {};
  }

  public attorneyPropChange() {
    const attorneyAreChanged = this.reportForm.dirty;

    if (attorneyAreChanged) {
      const keys = ['attorney_details', 'attorney_file_attachment', 'attorney_file_sign_attachments'];

      if (this.reportForm.controls.is_ceo.value) {
        keys.forEach((key) => {
          this.reportForm.controls[key].clearValidators();
          this.reportForm.controls[key].setValue(null, { emitEvent: false });
        });
      } else {
        ['attorney_details', 'attorney_file_attachment'].forEach((key) =>
          this.reportForm.controls[key].setValidators([Validators.required]),
        );
      }
      this.reportForm.controls.attorney_details.markAsDirty();
      this.reportForm.controls.attorney_file_attachment.markAsDirty();
      this.reportForm.controls.attorney_file_sign_attachments.markAsDirty();

      this.reportForm.updateValueAndValidity();
    }
  }

  public clearRegion(): void {
    const region_ids: number[] = this.reportForm.controls?.territory_org?.value?.region_ids;
    const selectedRegion: number = this.reportForm.controls?.region_id?.value;
    if (!region_ids?.includes(selectedRegion)) this.reportForm.controls.region_id.setValue(null);
    this.cdr.detectChanges();
  }

  public likeFormControl(control: AbstractControl): UntypedFormControl {
    return control as UntypedFormControl;
  }
}
