import { Location } from '@angular/common';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatusCodes } from 'http-status-codes';
import { get } from 'lodash';
import { IndividualConfig, ToastService } from 'ng-uikit-pro-standard';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, catchError, tap, map } from 'rxjs/operators';
import { EnvironmentType } from 'src/app/providers/_const/environment.type';
import { SUPPRESS_TOASTR_ERROR } from 'src/app/providers/_const/error.const';
import { EFileType } from 'src/app/providers/_interfaces/download.interface';
import { IPageError } from 'src/app/providers/_interfaces/page.error';
import { AuthenticationService } from 'src/app/providers/_services/authentication.service';
import { ErrorPageService } from 'src/app/providers/_services/error.page.service';
import { LoadedService } from 'src/app/providers/_services/load.service';
import { SignatureService } from 'src/app/providers/_services/signature.service';
import { SvcRestService } from 'src/app/providers/_services/svc.rest.service';
import { checkUrl, deepClone, getIdentId } from 'src/app/providers/_utils/utils';
import { environment } from 'src/environments/environment';


const ToastrInfoConfig: IndividualConfig = {
  timeOut: 4000,
  positionClass: 'md-toast-bottom-right',
  tapToDismiss: false,
  toastClass: 'rpn-toast',
  enableHtml: true,
};

const ToastrErrorConfig: IndividualConfig = {
  timeOut: 10000,
  positionClass: 'md-toast-bottom-right',
  tapToDismiss: false,
  toastClass: 'rpn-toast',
  enableHtml: true,
  progressBar: true,
};
const xAccessTokenName = 'x-access-token';
const xIdentity = '\u0058\u002D\u0043\u006F\u006E\u0074\u0065\u006E\u0074\u002D\u0049\u0064';
interface IRequest {
  setHeaders: any;
  body: any;
  withCredentials: boolean;
  setParams?: any;
}

@Injectable({
  providedIn: 'root',
})
export class AppInterceptor implements HttpInterceptor {
  private xAccessToken = '';

  constructor(
    private location: Location,
    private toastr: ToastService,
    private authenticationService: AuthenticationService,
    private svcRestService: SvcRestService,
    private signatureService: SignatureService,
    private errorPageService: ErrorPageService,
    private cookieService: CookieService,
    private loadedService: LoadedService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.switchNextHandle(request, next);
  }

  private switchNextHandle(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isDadataSuggest = request.url.indexOf(`${environment.ADDRESS_SUGGEST_URL}`) >= 0;
    const isOpenApi = request.url.indexOf('knd.gov.ru') >= 0 || request.url.indexOf('/svc/dataexport') >= 0;
    const urlsReturnResponseByError = ['/api/svc/onv-monitoring/monitoring_permission'];

    if (isDadataSuggest) {
      const params = { headers: request.headers.set('Authorization', `Token ${environment.ADDRESS_SUGGEST_Authorization}`) };
      return next.handle(request.clone(params));

    } else if (isOpenApi) {
      return next.handle(request.clone());

    } else if (urlsReturnResponseByError.includes(request.url)) {
      return next.handle(request.clone()).pipe(
        catchError(() => of(new HttpResponse({ body: {} }))),
      );
    }

    this.svcRestService.checkCacheExpiryDate();
    return this.nextHandle(request, next);
  }

  private dispatchMessage(b: any, u: string) {
    return of(getIdentId(b, u));
  }

  private nextHandle(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const a = navigator.userAgent;
    const needSign = request?.body?.data && request.body.needSign && ['POST', 'PATCH', 'PUT'].includes(request.method);
    const str = request.body ? (needSign ? request.body : JSON.stringify(request.body)) : '';
    const url = decodeURI(request.urlWithParams).replace(/\+/g, ' ').replace(/\%2B/g, '+').replace(/\%26/g, '&').replace(/\%23/g, '#');

    const request$ = needSign
      ? this.signBodyHandler(request)
      : of({ body: request.body, setHeaders: {}, setParams: null });

    return request$.pipe(
      switchMap((res) => this.dispatchMessage(str, a + url).pipe(map((identity) => ({ ...res, identity })))),
      switchMap(({ body, setHeaders, setParams, identity }) => {
        if (['POST', 'PATCH', 'PUT', 'DELETE'].includes(request.method)) {
          console.log('clearCache')
          this.svcRestService.clearCache();
        }
        if (!this.xAccessToken) {
          try {
            this.xAccessToken = localStorage.getItem(xAccessTokenName) || '';
          } catch (err) {}
        }

        if (this.xAccessToken) setHeaders[xAccessTokenName] = this.xAccessToken;
        if (identity) setHeaders[xIdentity] = identity;

        const requestJSON: IRequest = { setHeaders, body, withCredentials: true };
        if (setParams) requestJSON.setParams = setParams;

        return next.handle(request.clone(requestJSON));
      }),
      catchError((error: HttpErrorResponse) => this.errorHandler(error, request)),
      tap((response: HttpResponse<any>) => {
        if (response?.headers?.has(xAccessTokenName)) {
          this.xAccessToken = response.headers.get(xAccessTokenName) || '';
          if (this.xAccessToken) {
            try { localStorage.setItem(xAccessTokenName, this.xAccessToken); } catch (err) {}
          }
        }

        this.showSuccessMessage(request, response);
      }),
    );
  }

  private switchContentTypeForSignRequest(request: HttpRequest<any>): string {
    return request.body.data instanceof FormData ? 'multipart/form-data-signed' : 'text/plain;charset=utf-8';
  }

  private signBodyHandler(request: HttpRequest<any>): Observable<{ body: any; setHeaders: { 'Content-Type': string }; setParams: any }> {
    return this.signatureService.signByCertificate(request.body.data).pipe(
      catchError(({ title, message }) => {
        this.toastr.error(message, title || 'Ошибка', ToastrErrorConfig);
        this.loadedService.loadFinish();
        return throwError({ title, message });
      }),
      map(({ sign, signature_hash, type }) => {
        const setHeaders = { 'Content-Type': this.switchContentTypeForSignRequest(request) };
        const setParams = { signature_hash, signature_source: EFileType[type] };
        return { body: sign, setHeaders, setParams };
      }),
    );
  }

  private errorHandler(error: HttpErrorResponse, request: HttpRequest<any>) {
    this.svcRestService.clearCache();
    this.loadedService.loadFinish();

    const message = (get(error, 'error.message') || '').replace(/\n/g, '<br>');

    if (request?.params?.has(SUPPRESS_TOASTR_ERROR)) {
      return throwError(error);
    }

    if (
      error.status === StatusCodes.TOO_MANY_REQUESTS && // StatusCode === 400
      error.error?.type === 'ThrottleRequestsException'
    ) {

      this.toastr.error(error.error.message, error.error.header, ToastrErrorConfig);
      return throwError(error);
    }

    if (
      error.status === StatusCodes.BAD_REQUEST && // StatusCode === 400
      error.error?.type === 'TokenMismatchException'
    ) {
      const isXSRFTokenExist = this.authenticationService.isXSRFTokenExist$.value;

      if (isXSRFTokenExist) {
        this.cookieService.deleteAll();
        this.authenticationService.isXSRFTokenExist$.next(false);
      }
      return throwError(error);
    }

    if (
      error.status === StatusCodes.BAD_REQUEST && // StatusCode === 400
      error.error?.type === 'DenyAndSendTextException'
    ) {
      this.toastr.info('', message || '', ToastrInfoConfig);
      return of(null);
    }

    if (error.status === StatusCodes.FORBIDDEN && error.url.includes('payments-management')) {
      error.error.message = 'Отсутствуют права на данное действие';
    }

    if (error.status === StatusCodes.UNAUTHORIZED) {
      // StatusCode === 401
      if (this.authenticationService.user$.value?.id === null) return of(null);

      if (request.url.indexOf('/api/auth') === 0) return throwError(error);

      this.authenticationService.throwFail();
      return of(null);
    } else if (error && error instanceof HttpErrorResponse && error.error) {

      console.log(error);
      if (this.checkNotificationsException(error)) {
        return this.throwErrorMsg('Невозможно удалить уведомление, направленное всей организации');
      }

      if (this.checkOnvRequestAdjustV2Exception(error, request)) {
        return this.errorPageService.onvRequestExceptionWithModal(message, 'Заявление на корректировку');
      }

      if (this.checkRefundException(error)) {
        if (environment.SYSTEM_TYPE === EnvironmentType.lk) return this.throwErrorMsg(message);

        return this.errorPageService.onvRequestExceptionWithModal(
          message + ' ' +
            (error.error.context.territory_org?.id
              ? `Доступно для - <a href="./company/${error.error.context.territory_org?.id}">${error.error.context.territory_org?.name}</a>`
              : ''),
          'У вас недостаточно прав для просмотра',
        );
      }

      if (this.checkAlreadyExistsPayments(error)) {
        return this.errorPageService.invalidSumException();
      }

      if (this.checkAlreadyExistsException(error)) {
        return this.errorPageService.onvRequestEditAlreadyExists(error);
      }

      if (this.checkInvalidSumExceptionException(error)) {
        return this.errorPageService.invalidSumException();
      }

      if (this.checkDeleteException(error) || this.checkOnvosUniqException(error) || this.checkFGGNLicenseException(error)) {
        setTimeout(() => this.toastr.error(message || '', 'Ошибка', ToastrErrorConfig), 500);
        return this.errorPageService.deleteException();
      }

      if (this.checkToastrDuplicateException(error)) {
        if (error.status === StatusCodes.MOVED_TEMPORARILY) {
          this.toastr.success(message || '', 'Выполнено', ToastrInfoConfig);
        } else if (request.url.indexOf('/api/svc/smev/response/') < 0 && error.status !== 423) {
          this.toastr.error(message || '', 'Ошибка', ToastrErrorConfig);
        }
      }

      if (request.method === 'GET') {
        this.serviceUnavailable(error, request);
      }
    }

    return throwError(error);
  }

  private serviceUnavailable(responseError: { error: IPageError; status: number }, request: HttpRequest<any>): void {
    this.errorPageService.clearErrorInStorage();

    if ([StatusCodes.BAD_GATEWAY, StatusCodes.FORBIDDEN, StatusCodes.SERVICE_UNAVAILABLE].includes(responseError?.status)) {
      const error = deepClone(responseError?.error);

      if (!error?.status_code) return;

      error.error_frontend_route = this.location.path() + location.search;
      error.error_backend_endpoint = request.urlWithParams;

      this.errorPageService.updateErrorInStorage(error);
    }
  }

  private checkInvalidSumExceptionException(error: HttpErrorResponse): boolean {
    return (
      error.error &&
      (error.error.url || '').slice(-11) === '/set-status' &&
      error.error.message &&
      error.error.message.indexOf &&
      !!['Отсутствуют суммы к начислению по отчету', 'Отсутствуют суммы по отчету'].find((text) => {
        return error.error.message.indexOf(text) === 0;
      })
    );
  }

  private checkDeleteException(error: HttpErrorResponse): boolean {
    return (
      error?.error?.message?.indexOf &&
      !!['Нельзя удалить'].find((text) => {
        return error.error.message.indexOf(text) === 0;
      })
    );
  }

  private checkOnvosUniqException(error: HttpErrorResponse): boolean {
    return (
      error.error.type === 'BadRequestHttpException' &&
      error.error.message.indexOf('Такой код ОНВОС, уже встречается в ранее созданном заявлении') >= 0
    );
  }

  private checkFGGNLicenseException(error: HttpErrorResponse): boolean {
    return error.error.type === 'BadRequestException' && error.error.message.indexOf('Если лицензия не является бессрочной') >= 0;
  }

  private checkAlreadyExistsPayments(error: HttpErrorResponse): boolean {
    return error?.error?.class === 'AfterSetStatusException' && error.error.message.indexOf('Платежные поручения по декларации') >= 0;
  }

  private checkRefundException(error: HttpErrorResponse): boolean {
    return (
      error?.error?.type === 'AuthProfileException' &&
      error.error.message.indexOf('не найден, проверьте адрес и повторите попытку') >= 0 &&
      error.error.context.territory_org?.name
    );
  }

  private checkAlreadyExistsException(error: HttpErrorResponse): boolean {
    return (
      (error?.error?.type === 'OnvRequestEditAlreadyExists' || error.error?.type === 'OnvRequestCorrectionAlreadyExists') &&
      error?.error?.context &&
      error.error.context.id
    );
  }

  private checkToastrDuplicateException(error: HttpErrorResponse): boolean {
    return error.error.message && !this.toastr.isDuplicate(error.error.message) && !this.toastr.isDuplicate(error.error.header);
  }

  private showSuccessMessage(request: HttpRequest<any>, response: HttpResponse<any>) {
    if (
      request &&
      (request.method === 'POST' || request.method === 'PATCH') &&
      response instanceof HttpResponse &&
      !(response.body && (response.body.status_link || response.body.profile)) &&
      !environment.production &&
      !this.toastr.isDuplicate('')
    ) {
      this.toastr.success('', 'Выполнено', ToastrInfoConfig);
    }
  }

  private throwErrorMsg(msg) {
    this.toastr.error(msg, 'Ошибка', ToastrErrorConfig);
    return of(null);
  }

  private checkNotificationsException(error: HttpErrorResponse): boolean {
    if (!checkUrl(error.error.url, '/api/svc/messages/$')) {
      return false;
    }

    return error.error.status_code === 403;
  }

  private checkOnvRequestAdjustV2Exception(error: HttpErrorResponse, request: HttpRequest<any>): boolean {
    if (!checkUrl(error.error.url, '/api/svc/onv/v2/onv_request_adjust_v2/$/set-status')) {
      return false;
    }

    const adjustTransitions = {
      3028: true,
      3029: true,
    };

    return error.error.status_code === 400 && adjustTransitions[request?.body?.status_transition_id];
  }
}
