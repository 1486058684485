<mdb-error *ngIf="!valid && errors" [mdbTooltip]="tooltipCtrl"
           class="rpn-validation-error">
  <ng-container *ngIf="errors.required && !value?.length">
    Обязательное поле
  </ng-container>
  <ng-container *ngIf="!errors.required && errors.message">
    <div [innerHTML]="errors.message"></div>
  </ng-container>
</mdb-error>

<ng-container *ngIf="valid">
  <mdb-error class="rpn-validation-error" *ngFor="let error of nonImportantErrors" [mdbTooltip]="errors">
    <div>{{error}}</div>
  </mdb-error>
</ng-container>

<mdb-error
  *ngIf="notSavedDataMessage && dirty && valid && !nonImportantErrors.length"
  class="rpn-validation-error"
  [mdbTooltip]="'Данные не сохранены'"
>
  Данные не сохранены
</mdb-error>

<ng-template #tooltipCtrl>
  <div [innerHTML]="errors.message"></div>
</ng-template>
